/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EventPolicyEnum } from './eventPolicyEnum';
import { ShopDeliveryEnum } from './shopDeliveryEnum';


export interface Event { 
    Id?: number;
    Created?: Date | null;
    Modified?: Date | null;
    Deleted?: Date | null;
    EventTypeId?: number | null;
    StartDateTime?: Date;
    EndDateTime?: Date;
    Name?: string | null;
    Description?: string | null;
    DescriptionShort?: string | null;
    DoorOpen?: Date;
    OnSale?: boolean;
    OnSaleFrom?: Date | null;
    OnSaleTill?: Date | null;
    Active?: boolean;
    ActiveFrom?: Date | null;
    ActiveTill?: Date | null;
    Programme?: string | null;
    TravelDirections?: string | null;
    AvailableInDistributix?: boolean;
    SyncWithACL?: boolean;
    OnSaleInBizzTix?: boolean;
    EventPlacementAvailable?: boolean;
    IdentificationRequired?: boolean;
    VisibleInShop?: boolean;
    VisibleInShopFrom?: Date | null;
    VisibleInShopTill?: Date | null;
    ImageId?: number | null;
    HomeImageId?: number | null;
    AwayImageId?: number | null;
    NameHomeTeam?: string | null;
    NameAwayTeam?: string | null;
    Named?: boolean;
    AssigningRatio?: number | null;
    EventLogoId?: number | null;
    HighlightInShop?: boolean;
    HasSoldOut?: boolean;
    PolicyId?: EventPolicyEnum;
    Classification?: string | null;
    SingleSeatCheckActive?: boolean;
    CancelableFromShop?: boolean;
    CancelableOrderedFrom?: Date | null;
    CancelableOrderedUntil?: Date | null;
    IsUniquelyAssigned?: boolean;
    DisplayCountdown?: boolean;
    VisibleInFanmanagement?: boolean;
    FantastixManageableFrom?: Date | null;
    FantastixManageableTill?: Date | null;
    FantastixOnSale?: boolean;
    FantastixOnSaleFrom?: Date | null;
    FantastixOnSaleTill?: Date | null;
    ShopDelivery?: ShopDeliveryEnum;
    ShowScanData?: boolean;
    BusinessTicketNamingRequired?: boolean;
    AdditionalAttribute?: string | null;
    ExternalTicketsVisibleInShop?: boolean;
    ExternalTicketsVisibleInShopFrom?: Date | null;
    ExternalTicketsVisibleInShopTill?: Date | null;
    ExternalTicketsSectionName?: string | null;
    VisibleInMyTickets?: boolean;
    DisplayPanorama?: boolean;
}

