import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpLoaderService } from '@tymes4-shared';
import { HttpEventType } from '@angular/common/http';
import {ConfirmService} from '@tymes4-shared';
import { TranslateService } from '@ngx-translate/core';
import { UploadMessageService } from '@tymes4-shared';
import { FileImportResult, MembershipImportService } from '../../api';
import { GenericErrorHandler } from '../../helpers/generic-error-handler';

@Component({
  selector: 'app-upload-membership-importfile',
  templateUrl: './upload-membership-importfile.component.html',
  styleUrls: ['./upload-membership-importfile.component.scss']
})
export class UploadMembershipImportFileComponent implements OnInit {
  public membershipId;
  public progress: number;
  public message: string;
  public uploadKey: string;
  public uploadErrors: string[];
  public uploadWarnings: string[];
  @ViewChild('file') fileInput;

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<UploadMembershipImportFileComponent>,
    public dialog: MatDialog,
    private loader: HttpLoaderService,
    private membershipImportService: MembershipImportService,
    private confirmService: ConfirmService,
    private errorHandler: GenericErrorHandler,
    private translate: TranslateService,
    private messageService: UploadMessageService,
  ) { }

  ngOnInit() {
    this.membershipId = this.passedData.membershipId;
  }

  uploadImportFile(files) {
    if (files === null || files === 'undefined' || !(files.length > 0)) {
      return;
    }

    const file = files[0];
    const blobArray : Array<Blob> = new Array<Blob>();
    blobArray.push(file);

    this.loader.open();

    let observable = this.passedData.renew ?
      this.membershipImportService.uploadRenewalImportFile(this.membershipId, blobArray) :
      this.membershipImportService.uploadMembershipImportFile(this.membershipId, blobArray);

    observable.subscribe((result: FileImportResult) => {
      this.loader.close();
      this.uploadKey = result.Key;
      this.uploadWarnings = result.Warnings;
      this.uploadErrors = result.Errors;

      if (this.uploadErrors.length > 0) {
        this.handleUploadErrors();
      } else {
        if (this.uploadWarnings.length > 0) {
          this.handleUploadWarnings();
        } else {
          this.dialogRef.close(true);
        }
      }
    },
    error => { this.errorHandler.handle(error); }
    );

    this.fileInput.nativeElement.value = '';
  }

  handleUploadErrors() {
    const title = 'DIALOG.MEMBERSHIP_IMPORT.UPLOAD_FILE.FAILED.CONFIRM.TITLE';
    const message = 'DIALOG.MEMBERSHIP_IMPORT.UPLOAD_FILE.FAILED.CONFIRM.MSG';
    const messageAdditional = 'DIALOG.MEMBERSHIP_IMPORT.UPLOAD_FILE.FAILED.CONFIRM.ADDITIONAL';
    this.messageService.confirm(
      {title: title, errors: this.uploadErrors, message: message, messageAdditional: messageAdditional, isWarning: false, okonly: true},
    ).subscribe();
  }

  handleUploadWarnings() {
    const title = 'DIALOG.MEMBERSHIP_IMPORT.UPLOAD_FILE.WARNING.CONFIRM.TITLE';
    const message = 'DIALOG.MEMBERSHIP_IMPORT.UPLOAD_FILE.WARNING.CONFIRM.MSG';
    const messageAdditional = 'DIALOG.MEMBERSHIP_IMPORT.UPLOAD_FILE.WARNING.CONFIRM.ADDITIONAL';
    this.messageService.confirm(
      {title: title, errors: this.uploadWarnings, message: message, messageAdditional: messageAdditional, isWarning: true, okonly: true},
      ).subscribe(confirmed => {
        if (confirmed) {
          this.dialogRef.close(true);
        }
      });
  }
}
