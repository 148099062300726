import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import * as FileSaver from 'file-saver';
import { CreateInvoiceBatchByOrderIdsArgs, InvoiceBatchService, LongRunningOperationService, MailTemplateService, OrderActionService, OrderDetailsService, OrderDetailsView, OrderManagementService, OrderService, PendingOrderService, SendMailETicketArgs, SendPaymentInvitationArgs, TicketDownloadArgs, TicketPrintArgs, TicketService } from "../../../api";
import { ConfirmService, DialogHelper, DialogWidth, HttpLoaderService, NotificationCommunicationService, PrintOrDownloadTicketsComponent, SnackbarService } from "@tymes4-shared";
import { ApplicationModuleHelperService } from "../../../services/application-module.service";
import { PrintOrderHardcardsComponent } from "../../../dialogs/print-order-hardcards/print-order-hardcards.component";
import { ManualMailDialogComponent, ManualMailDialogParameters, ManualMailDialogResult } from "../../../dialogs/manual-mail-dialog/manual-mail-dialog.component";
import { AuthService } from "../../../services/auth.service";
import { AddDiscountCodeFormComponent } from "../../../dialogs/add-discount-code-form/add-discount-code-form.component";
import { ResultHandler } from "../../../common/result-handler";
import { GenericErrorHandler } from "../../../helpers/generic-error-handler";
import { PaymentComponent } from "../../../dialogs/cash-register/payment/payment.component";
import { first } from "rxjs/operators";

@Component({
  selector: 'app-order-detail-actions',
  templateUrl: './order-detail-actions.component.html'
})
export class OrderDetailActionsComponent implements OnInit {
  static numberOfButtons = 0;
  static buttons = {
    Close: OrderDetailActionsComponent.numberOfButtons++,
    Edit: OrderDetailActionsComponent.numberOfButtons++,
    SendPaymentRequest: OrderDetailActionsComponent.numberOfButtons++,
    DownloadETickets: OrderDetailActionsComponent.numberOfButtons++,
    PrintOrderConfirmation: OrderDetailActionsComponent.numberOfButtons++,
    PrintInvoice: OrderDetailActionsComponent.numberOfButtons++,
    PrintTickets: OrderDetailActionsComponent.numberOfButtons++,
    PayByInvoice: OrderDetailActionsComponent.numberOfButtons++,
  };
  @ViewChild('paymentOverview') paymentOverview;
  public isIbaActive: boolean = false;
  public showButton = [];
  public isInArchiveMode = this.authService.isInArchiveMode();
  public isInEditMode: boolean = false;

  @Output() editModeOutput = new EventEmitter<boolean>();
  @Output() orderIsPaid = new EventEmitter<boolean>();
  @Input() orderDetails: OrderDetailsView;
  constructor(
    private apploader: HttpLoaderService,
    private router: Router,
    private translate: TranslateService,
    private applicationModuleHelperService: ApplicationModuleHelperService,
    private pendingOrderService: PendingOrderService,
    private confirmService: ConfirmService,
    private snackBar: SnackbarService,
    private orderService: OrderService,
    private orderActionService: OrderActionService,
    private orderDetailsService: OrderDetailsService,
    private invoiceBatchService: InvoiceBatchService,
    private dialog: MatDialog,
    private resultHandler: ResultHandler,
    private genericErrorHandler: GenericErrorHandler,
    private ticketService: TicketService,
    private mailTemplateService: MailTemplateService,
    private authService: AuthService,
    private notificationCommunicationService : NotificationCommunicationService,
    private lroService: LongRunningOperationService
  ) { }

  ngOnInit(): void {
    this.applicationModuleHelperService.getActiveModules().subscribe((activeModules) => {
      if (activeModules !== null) {
        this.isIbaActive = activeModules.some((module) => module.toUpperCase() == 'IBA')
      }
    });

    for (const key in OrderDetailActionsComponent.buttons) {
      this.showButton[OrderDetailActionsComponent.buttons[key]] = true;
    }
  }

  prolongSelectedOrder() {
    const orderId = this.orderDetails.Id;

    //ask confirmation of the user

    this.confirmService.confirm({
      title: this.translate.instant('SALES.ORDERS.DETAILS.CONFIRM.PROLONG.TITLE'),
      message: this.translate.instant('SALES.ORDERS.DETAILS.CONFIRM.PROLONG.MSG')
    }).subscribe(confirmed => {
      if (confirmed) {

        this.pendingOrderService.createProlongationByOrderId(orderId).subscribe((pendingOrderId: any) => {
          if (pendingOrderId) {
            sessionStorage.setItem('pendingOrderId', pendingOrderId);
            this.router.navigate(['/sales/orders/sell-tickets']);
          } else {
            this.confirmService.confirm({
              title: this.translate.instant('SALES.ORDERS.DETAILS.CONFIRM.PROLONG.NOT-POSSIBLE.TITLE'),
              message: this.translate.instant('SALES.ORDERS.DETAILS.CONFIRM.PROLONG.NOT-POSSIBLE.MSG'),
              okonly: true
            }).subscribe();
          }
        });
      }
    });
  }

  payOrder() {
    const title = this.translate.instant('SALES.CASH-REGISTER.ORDER-OVERVIEW.DIALOG.PAY-ORDERS.TITLE');
    const dialogRef: MatDialogRef<any> = this.dialog.open(PaymentComponent, {
      width: '1024px',
      disableClose: true,
      data: {title: title, payload: [this.orderDetails.Id], bookingCustomerId: this.orderDetails.CustomerId}
    });

    dialogRef.afterClosed().subscribe(submitForm => {
      if (submitForm) {
        this.snackBar.open(this.translate.instant('GENERIC.SNACKBAR.PAYMENT-HANDLED'), 'GENERIC.SNACKBAR.CLOSE');
        this.orderIsPaid.emit(true);
      }
    });
  }

  initiatePayPerEmail() {
    const amountToRequest = (this.orderDetails.Balance - this.orderDetails.PendingPaymentBalance).toFixed(2);

    this.confirmService.confirm({
      title: this.translate.instant('GENERIC.CONFIRM.WARNING.TITLE.ARE-YOU-SURE'),
      message: this.translate.instant('SALES.ORDERS.DETAILS.DIALOG.SEND-PAY-PER-MAIL.MSG', {amount: amountToRequest, primaryContactEmail: this.orderDetails.PrimaryContactEmail})
    }).subscribe(confirmed => {
      if (confirmed) {

        this.apploader.open();

        const args : SendPaymentInvitationArgs = {
          Email: this.orderDetails.PrimaryContactEmail
        }

        this.orderService.sendPaymentRequest(this.orderDetails.Id, args).subscribe((ok: boolean) => {
          this.apploader.close();
          if (ok === true) {
            this.snackBar.open(this.translate.instant('GENERIC.SNACKBAR.SEND-PAY-PER-MAIL.SUCCESS'), 'GENERIC.SNACKBAR.CLOSE');
            this.paymentOverview.loadPaymentHistoryForOrder(this.orderDetails.Id);
          } else {
            this.confirmService.confirm({
              title: this.translate.instant('SALES.ORDERS.DETAILS.CONFIRM.SEND-PAY-PER-MAIL.FAILED.TITLE'),
              message: this.translate.instant('SALES.ORDERS.DETAILS.CONFIRM.SEND-PAY-PER-MAIL.FAILED.MSG'),
              okonly: true
            }).subscribe();
          }
        });
      }
    });
  }

  getButtons() {
    return OrderDetailActionsComponent.buttons;
  }

  printPackagingSlip() {
    this.apploader.open();

    this.orderService.getPackagingSlip(this.orderDetails.Id).subscribe((pdf: any) => {
      FileSaver.saveAs(pdf, `order_confirmation_${this.orderDetails.Id}.pdf`);
      this.apploader.close();
    });
  }

  printInvoice() {
    this.apploader.open();

    this.orderService.getInvoice(this.orderDetails.Id).subscribe((pdf: any) => {
      FileSaver.saveAs(pdf, `invoice_${this.orderDetails.Id}.pdf`);
      this.apploader.close();
    });
  }

  createInvoice() {
    const orderId = this.orderDetails.Id;

    this.confirmService.confirm({
      title: this.translate.instant('SALES.ORDERS.DETAILS.CONFIRM.DIRECT-INVOICE.TITLE'),
      message: this.translate.instant('SALES.ORDERS.DETAILS.CONFIRM.DIRECT-INVOICE.MSG')
    }).subscribe(confirmed => {
      if (confirmed) {
        this.apploader.open();

        const args: CreateInvoiceBatchByOrderIdsArgs = {
          OrderIds: [orderId]
        }

        this.invoiceBatchService.createInvoiceBatchByOrderIds(args).subscribe((invoicebatchId: number) => {
          // Batch direct factureren.
          this.invoiceBatchService.invoicing(invoicebatchId).subscribe(() => {
            this.snackBar.open(this.translate.instant('GENERIC.SNACKBAR.DIRECT-INVOICE-CREATED'));
            this.apploader.close();
          });
        });
      }
    });
  }

  displayHardcardDialog() {
    const title = this.translate.instant('SALES.ORDERS.DETAILS.DIALOG.PRINT-HARDCARDS.TITLE');
    const dialogRef: MatDialogRef<any> = this.dialog.open(PrintOrderHardcardsComponent, {
      width: '1024px',
      disableClose: true,
      data: {title: title, payload: this.orderDetails}
    });
    dialogRef.afterClosed().subscribe();

    return false;
  }

  displayPrintDialog() {
    this.apploader.open();

    this.orderDetailsService.getPrintDetails(this.orderDetails.Id, false).subscribe(data => {

      this.apploader.close();

      const dialogOptions = DialogHelper.GetDialogOptions(DialogWidth.lg, {
        title: this.translate.instant('SALES.ORDERS.DETAILS.DIALOG.PRINT-TICKETS.TITLE'),
        payload: data, mode: 'print', isIbaActive: this.isIbaActive
      });

      const dialogRef: MatDialogRef<any> = this.dialog.open(PrintOrDownloadTicketsComponent, dialogOptions);

      dialogRef.afterClosed()
        .subscribe(submitForm => {

          if (submitForm === null) {
            // Nothing to do. The download is closed without pressing download/print.
            return;
          }

          this.apploader.open();

          const args: TicketPrintArgs = {
            OrderId: this.orderDetails.Id,
            SelectedEventPlacementIds: submitForm.SelectedEventplacementIds,
            PackagingSlipOption: submitForm.packagingSlip,
            CutMode: submitForm.cutMode,
            isDuplicate: false,
            DuplicationReasonId: submitForm.DuplicationReasonId,
          }

          //to the download
          this.ticketService.printTicket(args).subscribe(data => {
            this.apploader.close();
            this.snackBar.open(this.translate.instant('GENERIC.SNACKBAR.PRINTJOB-CREATED'), 'GENERIC.SNACKBAR.CLOSE');
          }, error => {

            this.apploader.close();

            this.confirmService.confirm({
              title: this.translate.instant('GENERIC.SNACKBAR.SOMETHING-WENT-WRONG'),
              message: this.translate.instant('SALES.ORDERS.DETAILS.CONFIRM.PRINT-TICKETS.FAILED.MSG'),
              okonly: true
            }).subscribe();
          });

        }
      );
    });

    return false;
  }

  displayDownloadDialog() {
    this.apploader.open();
    setTimeout(() => {
      this.orderDetailsService.getPrintDetails(this.orderDetails.Id, true).subscribe(data => {

        this.apploader.close();

        const dialogOptions = DialogHelper.GetDialogOptions(DialogWidth.lg, {
          title: this.translate.instant('SALES.ORDERS.DETAILS.CONFIRM.DOWNLOAD-ETICKETS.TITLE'),
          payload: data, mode: 'download', allowSalesChannelSelect: true, isIbaActive: this.isIbaActive
        });

        const dialogRef: MatDialogRef<any> = this.dialog.open(PrintOrDownloadTicketsComponent, dialogOptions);

        dialogRef.afterClosed()
          .subscribe(submitForm => {

            if (submitForm === null) {
              // Nothing to do. The download is closed without pressing download
              return;
            }

            this.apploader.open();

            const args: TicketDownloadArgs = {
              OrderId: this.orderDetails.Id,
              SelectedEventPlacementIds: submitForm.SelectedEventplacementIds,
              SalesChannelId: submitForm.SalesChannelId,
              isDuplicate: false,
              DuplicationReasonId: submitForm.duplicationReasonId
            }

            if (submitForm.DownloadInBackground) {
              this.lroService.registerTicketDownload(args)
              .pipe(first())
              .subscribe(status => {
                this.notificationCommunicationService.updateNotificationsAndLROS();
                this.apploader.close();
                this.snackBar.open('GENERIC.SNACKBAR.BACKGROUND_JOB_CREATED', 'GENERIC.SNACKBAR.CLOSE');
              });
            }
            else {
              //to the download
              this.ticketService.downloadTicket(args).subscribe((data: any) => {
                this.apploader.close();
                FileSaver.saveAs(data, `E-tickets_order_${this.orderDetails.Id}.zip`);
              });
            }
          });
      });
    });

    return false;
  }

  displayEmailDialog() {
    let data = <ManualMailDialogParameters>{};
    data.title = 'ORDER.DETAILS.TICKETS.SEND-TICKETS.TITLE';
    data.email = this.orderDetails.CustomerEmail;
    data.showSalesChannelSelection = true;

    this.apploader.open();

    this.orderDetailsService.getPrintDetails(this.orderDetails.Id, true).subscribe(printDetails => {
      data.attachments = printDetails.PrintableTickets;
      data.salesChannels = printDetails.ETicketPrintableChannels;

      this.mailTemplateService.getConfirmationMail(this.orderDetails.Id).subscribe((result) => {
        this.apploader.close();
        data.body = result.Body;
        data.subject = result.Subject;

        const dialogRef: MatDialogRef<any> = this.dialog.open(ManualMailDialogComponent, {
          width: '720px',
          disableClose: true,
          data: data
        });

        dialogRef.afterClosed()
          .subscribe((result: ManualMailDialogResult) => {
            if (!result) {
              return;
            }

            this.apploader.open();

            const args: SendMailETicketArgs = {
              Email: result.email,
              Subject: result.subject,
              Body: result.body,
              SalesChannelId: result.salesChannelId,
              EventPlacementIds: result.attachments
            }

            this.orderService.sendETicketMail(this.orderDetails.Id, args).subscribe((a) => {
              this.apploader.close();
              this.snackBar.open(this.translate.instant('GENERIC.SNACKBAR.EMAIL-HAS-BEEN-SENT'), 'GENERIC.SNACKBAR.CLOSE');
            });
          });
      });
    });

    return false;
  }

  toggleEditMode() {
    this.isInEditMode = !this.isInEditMode;
    this.editModeOutput.emit(this.isInEditMode);
  }

  addDiscountCode() {
    const title = this.translate.instant('SALES-ENTITY-MODIFICATION.DIALOG.TITLE.INPUT-DISCOUNT');

    const options = DialogHelper.GetDialogOptions(DialogWidth.lg, { title: title });
    const dialogRef: MatDialogRef<any> = this.dialog.open(AddDiscountCodeFormComponent, options);

    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.orderActionService.applyDiscountCodes(this.orderDetails.Id, JSON.stringify(res.value)).subscribe(
        result => {
          this.resultHandler.handle(result);
        },
        error => {
          if (!this.resultHandler.handle(error?.error)) {
            this.genericErrorHandler.handle(error);
          }
        }
      );
    });
  }
}
