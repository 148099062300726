import { AbstractControl, ValidationErrors, FormGroup, FormControl } from '@angular/forms';
import { Injectable } from "@angular/core";
import { AssignmentPolicyService } from '../../api';

@Injectable()
export class AssignmentPolicyValidator {
  constructor(private assignmentPolicyService: AssignmentPolicyService) { }

  public editingObject: any;

  assignmentPolicyCodeTaken = (control: AbstractControl) => {
    return new Promise<ValidationErrors | null>((resolve) => {
      const value = control.value;

      if (!value) {
        resolve(null);
        return;
      }

      this.assignmentPolicyService.getAssignmentPolicyByCode(value).subscribe({
        next: (data) => {
          if (data && data.Id !== this.editingObject?.Id) {
            resolve({ codetaken: true });
          } else {
            resolve(null);
          }
        },
        error: () => {
          resolve(null);
        },
      });
    });
  };

  static ValidateDates = (group: FormGroup) => {

    if (group.controls.RevokeStartDate.dirty === true || group.controls.RevokeEndDate.dirty === true) {
      let startCheckDate = group.controls.RevokeStartDate.value;
      let endCheckDate = group.controls.RevokeEndDate.value;

      if (startCheckDate > endCheckDate) {
        group.controls.RevokeStartDate.setErrors({ startDateAfterEndDate: true }, { emitEvent: true });
        group.controls.RevokeEndDate.setErrors({ endDateBeforeStartDate: true }, { emitEvent: true });

        return ({ startDateAfterEndDate: true, endDateBeforeStartDate: true });
      }
      else {
        if (group.controls.RevokeStartDate.hasError('startDateAfterEndDate')) {
          group.controls.RevokeStartDate.setErrors(null, { emitEvent: true });
        }
        if (group.controls.RevokeEndDate.hasError('endDateBeforeStartDate')) {
          group.controls.RevokeEndDate.setErrors(null, { emitEvent: true });
        }
        return null;
      }
    }
  }

  static ValidateTimeSpan = (control: FormControl) => {
    if (control.dirty === true) {
      const timeSpanRegex = /^((?<DAYS>\d{1,})\.)?(?<HOURS>\d{2})(:(?<MINUTES>\d{2}))?(:(?<SECONDS>\d{2}))?$/;
      var match = control.value.match(timeSpanRegex);
      if (!match) {
        return {'invalidTimeSpan': true };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
}
