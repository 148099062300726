import { Inject, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import {LoggingService} from '@tymes4-shared';
import { BackofficeConfigModel, EntraService } from '../../api';
import { BASE_BO_CONFIG } from '../../../injectors';

@Injectable()
export class AuthGuard implements CanActivate {
  
  public authToken;

  constructor(
      @Inject(BASE_BO_CONFIG) public config: BackofficeConfigModel,
      private router: Router, 
      private logging : LoggingService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (sessionStorage.getItem('jwt') != null) {
      this.logging.log('User is authenticated with token.');
      return true;
    }

    if (this.config && this.config.EntraConfig && this.config.EntraConfig.Enabled)
      this.router.navigate(['/sessions/entra']);
    else 
      this.router.navigate(['/sessions/signin']);
    
    return false;
  }
  
}
