import { Injectable } from "@angular/core";
import { HttpLoaderService } from '@tymes4-shared';
import {ConfirmService} from '@tymes4-shared';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class GenericErrorHandler {

  constructor(private loader: HttpLoaderService, private confirmService: ConfirmService, private translate: TranslateService) { }

  handle(error: any) {
    this.loader.close();

    let message: string;
    if (error?.error) error = error.error;
    if (error?.Reference) {
      message = this.translate.instant('GENERIC.CONFIRM.ERRORS.MESSAGE.EDIT-COULD-NOT-EXECUTE-WITH-REFERENCE', error);
    }
    else {
      message = this.translate.instant('GENERIC.CONFIRM.ERRORS.MESSAGE.EDIT-COULD-NOT-EXECUTE-TRY-AGIN-LATER');
    }

    this.confirmService.confirm({
      title: this.translate.instant('GENERIC.CONFIRM.ERRORS.TITLE.SOMETHING-WENT-WRONG'),
      message: message,
      okonly: true
    }).subscribe((result) => {});
  }
}
