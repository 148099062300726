<div class="object-actions mt-3" *ngIf="!isInArchiveMode">
  <ng-container *ngIf="isInEditMode">
    <button (click)="addDiscountCode()" class="btn btn-primary border btn-small" *ngIf="orderDetails !== null">
      <i class="far fa-plus-square"></i>{{ 'SALES-ENTITY-MODIFICATION.OVERVIEW.BUTTON.ADD-DISCOUNT' | translate }}
    </button>
  </ng-container>

  <ng-container *ngIf="!isInEditMode">
    <button (click)="prolongSelectedOrder()" [disabled]="orderDetails.HasProlongationLines"
      class="btn btn-primary border btn-sm px-2 py-1" type="button">
      <i class="fas fa-redo"></i>
      {{'SALES.ORDERS.DETAILS.BTN.PROLONG' | translate | uppercase}}
    </button>
    
    <button (click)="payOrder()"
      class="btn btn-primary border btn-sm px-2 py-1" type="button">
      <i class="fas fa-credit-card"></i>
      {{'GENERIC.BUTTON.PAY' | translate | uppercase}}
    </button>

    <button *ngIf="showButton[getButtons().SendPaymentRequest]" (click)="initiatePayPerEmail()"
      [disabled]="orderDetails.PayPerMailAllowed == false" class="btn btn-primary border btn-sm px-2 py-1" type="button">
      <i class="far fa-credit-card"></i>{{'SALES.ORDERS.DETAILS.BTN.SEND-PAYMENT-REQUEST' | translate | uppercase}}</button>

    <span class="separator"></span>
    <button (click)="printPackagingSlip()" *ngIf="showButton[getButtons().PrintOrderConfirmation]"
      class="btn btn-primary border btn-sm px-2 py-1" type="button">
      <i class="fas fa-receipt"></i>
      {{'SALES.ORDERS.DETAILS.BTN.ORDER-CONFIRMATION' | translate | uppercase}}
    </button>

    <button (click)="printInvoice()" *ngIf="showButton[getButtons().PrintInvoice]"
      class="btn btn-primary border btn-sm px-2 py-1" type="button"><i class="fas fa-file-invoice"></i>{{'SALES.ORDERS.DETAILS.BTN.INVOICE' | translate | uppercase}}</button>

    <button class="btn dropdown-toggle btn-primary border btn-sm px-2 py-1" type="button" id="dropdownMenuButton"
      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i class="fas fa-ticket-alt"></i>
      {{'ORDER.DETAILS.BUTTON.TICKETS' | translate | uppercase}}
    </button>

    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" x-placement="bottom-start"
      style="position: absolute; transform: translate 3d(0px, 41px, 0px); top: 0px; left: 0px; will-change: transform;">
      <a class="dropdown-item" (click)="displayDownloadDialog();"
        [class.disabled]="orderDetails.AllowEticketPrinting === false" href="#">
        <i class="fas fa-download mr-2"></i>
        {{'ORDER.DETAILS.BUTTON.TICKETS.DOWNLOAD-ETICKET' | translate | uppercase}}
      </a>
      <a class="dropdown-item" (click)="displayEmailDialog();"
        [class.disabled]="orderDetails.AllowEticketMailing === false" href="#">
        <i class="fas fa-envelope mr-2"></i>
        {{'ORDER.DETAILS.BUTTON.TICKETS.SEND-ETICKET' | translate | uppercase}}
      </a>
      <a class="dropdown-item" (click)="displayPrintDialog()" [class.disabled]="orderDetails.AllowSoftcard === false"
        href="#">
        <i class="fas fa-print mr-2"></i>
        {{'ORDER.DETAILS.BUTTON.TICKETS.PRINT' | translate | uppercase}}
      </a>
      <a class="dropdown-item" (click)="displayHardcardDialog()"
        [class.disabled]="orderDetails.AllowHardcardPrinting === false" href="#">
        <i class="fas fa-address-card mr-2"></i>
        {{'ORDER.DETAILS.BUTTON.TICKETS.PRINT-HARDCARDS' | translate | uppercase}}
      </a>
    </div>
  </ng-container>

  <span *ngIf="orderDetails !== null && !orderDetails.Locked && orderDetails.PayByInvoice && !orderDetails.IsInvoiced">
    <span class="separator"></span>
    <button (click)="createInvoice()" class="btn btn-warning border btn-sm px-2 py-1" type="button"><i
        class="fas fa-file-invoice"></i>{{'SALES.ORDERS.DETAILS.BTN.DIRECT-INVOICE' | translate | uppercase}}</button>
  </span>
  <span *ngIf="(!isReadOnly || showButton[getButtons().Edit])">
    <span class="separator"></span>
    <button *ngIf="!isInEditMode" (click)="toggleEditMode()" class="btn btn-warning border btn-sm px-2 py-1" type="button">
      <i class="far fa-edit"></i>{{'GENERIC.BUTTON.EDIT' | translate | uppercase}}
    </button>
    <button *ngIf="isInEditMode" (click)="toggleEditMode()" class="btn btn-secondary border btn-sm px-2 py-1" type="button">
      <i class="far fa-ban"></i>{{'GENERIC.BUTTON.CLOSE' | translate | uppercase}}
    </button>
  </span>
</div>
