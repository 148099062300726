import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderBlockedService } from '../../api';
import { ApplicationModuleHelperService } from '../../services/application-module.service';


@Component({
  selector: 'app-order-blocked-details',
  templateUrl: './order-blocked-details.component.html',
  styleUrls: ['./order-blocked-details.component.scss']
})
export class OrderBlockedDetailsComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              private orderBlockedService: OrderBlockedService,
              public dialogRef: MatDialogRef<OrderBlockedDetailsComponent>,
              private translate: TranslateService,
              private applicationModuleHelperService: ApplicationModuleHelperService
            ) { }

  @ViewChild('pagerComponent') pagerComponent;

  orderBlockedData: any;
  additionalInfo: any;
  additionalInfoC3V: string;
  pagedOrderLines = [];
  lastUsedPage = null;
  isIbaActive: boolean = false;

  ngOnInit() {
    this.orderBlockedData = this.passedData.payLoad;

    this.orderBlockedService.getOrderBlockedDetails(this.orderBlockedData.Id).subscribe(data => {
      if (data) {
        this.additionalInfo = data;
        // C3V Json
        if (this.orderBlockedData.Source === 2 && this.additionalInfo.JSON) {
          this.additionalInfoC3V = this.parseJSONAdditional(this.additionalInfo.JSON);
        }
        this.setSearchAndPage(1);
      }
    });

    this.applicationModuleHelperService.getActiveModules().subscribe((activeModules) => {
      if (activeModules !== null) {
        this.isIbaActive = activeModules.some((module) => module.toUpperCase() == 'IBA')
      }
    })
  }

  doPage(pageArgs) {
    this.setSearchAndPage(pageArgs.pageNr);
  }

  setSearchAndPage(pageNr: number) {
    if (this.additionalInfo && this.additionalInfo.OrderLines) {
      this.lastUsedPage = pageNr;
      const length = this.additionalInfo.OrderLines.length; // we need a copy
      this.pagerComponent.setObjectCount(length);
      this.pagedOrderLines = this.additionalInfo.OrderLines.slice((pageNr - 1) * this.pagerComponent.pageSize, (pageNr) * this.pagerComponent.pageSize);
      this.pagerComponent.setPage(pageNr);
    }
  }

  parseJSONAdditional(json) {
    const jSONObject = JSON.parse(json);
    let data = '';

    const cvkData = jSONObject['CVK_N'];
    if (cvkData) {
      data += this.translate.instant('SALES.ORDERS.BLOCKED.DIALOG.DETAILS.CLUB-PREFERENCES') + ': ';
      for (let i = 1; i <= cvkData; i++) {
        if (i > i) {
          data += ', ';
        }
        data += jSONObject['CVK_' + i];
      }
      data += '\r\n';
    }

    const blkData = jSONObject['BLK_N'];
    if (blkData) {
      data += this.translate.instant('SALES.ORDERS.BLOCKED.DIALOG.DETAILS.BLACKLIST-PERIODS') + ': ';
      for (let i = 1; i <= blkData; i++) {
        if (i > i) {
          data += ', ';
        }
        data += jSONObject['BLK_' + i + '_BD'] + ' t/m ' + jSONObject['BLK_' + i + '_ED'];
      }
      data += '\r\n';
    }
    return data;
  }

  constructCustomerIdentificationModel(order, orderLine){
    if(order != null){
      return {
        CalculatedName : order.CustomerCalculatedName,
        IsIdentified: order.CustomerIsIdentified,
        CustomerNumber: order.CustomerNumber,
      }
    }
    return {
      CalculatedName : orderLine.TicketCustomerCalculatedName,
      IsIdentified: orderLine.TicketCustomerIsIdentified,
      CustomerNumber: orderLine.TicketCustomerNumber,
    }
  }
}
