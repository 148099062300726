
<!-- MODAL HEADER-->
<div class="row modal-bar">

  <div class="col-sm-6"><h5 class="modal-title">{{ passedData.title }}</h5></div>

  <div class="col-sm-6">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>

<form [formGroup]="form" class="form-content">

  <div class="row">
    <div class="col-md-6">
      <div class="important-info info-highlight">

        <div class="row">
          <div class="col-5">
            <p class="info-label">{{'SALES.ORDERS.DETAILS.LABEL.ORDERCUSTOMER' | translate}}</p>
            <p class="info-data">{{this.billingCustomer.Name}}</p>
          </div>
          <div class="col-1">
            <i class="fas fa-user"></i>
          </div>
          <div class="col-5">
            <p class="info-label">{{'SALES.ORDERS.DETAILS.LABEL.TOTAL-AMOUNT' | translate}}</p>
            <p class="info-data">
              {{ calculateTotal('Total') | currency:'EUR' }}
            </p>
          </div>
          <div class="col-1">
            <i class="far fa-credit-card"></i>
          </div>
        </div>

      </div>
    </div>


    <div class="col-lg-6">

      <div class="row">
        <div [ngClass]="form.value.PayByInvoice === '1' ? 'col-3 col-sm-3 col-xs-6' : 'col-6'">
          <div class="form-group">
            <label class="form-control-label d-block">{{'SALES.ORDERS.DETAILS.LABEL.PAYMENT-METHOD' | translate}}<span class="tx-danger">*</span></label>

            <div class="form-check mr-3 mb-3 d-inline-block">
              <input class="form-check-input" type="radio" id="PayDirect" formControlName="PayByInvoice" value="0" checked="true">
              <label class="form-check-label">
                {{'SALES.ORDERS.DETAILS.LABEL.DIRECT-PAYMENT' | translate}}
              </label>
            </div>

            <div class="form-check mr-3 mb-3 d-inline-block">
              <input class="form-check-input" type="radio" id="PayByInvoice" formControlName="PayByInvoice" value="1" checked="true">
              <label class="form-check-label">
                {{'SALES.ORDERS.DETAILS.LABEL.INVOICE' | translate}}
              </label>
            </div>

          </div>
        </div>
        <div class="col-3 col-sm-3 col-xs-6" *ngIf="this.form.value.PayByInvoice === '1'">
          <div class="form-group">
            <label class="form-control-label d-block">{{'SALES.ORDERS.DETAILS.LABEL.PROCESS-INVOICE' | translate}}<span class="tx-danger">*</span></label>

            <div class="ng-input">
              <ng-select [items]="invoiceBatchOptions" bindLabel="Name" bindValue="Value" formControlName="IncludeInInvoiceBatch" [clearable]="false" notFoundText="{{ 'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}"></ng-select>
            </div>

          </div>
        </div>
        <div class="col-6">
          <div class="form-group" *ngIf="this.billingCustomer.CustomerType === 1">
            <label class="form-control-label">
              {{'SALES.ORDERS.DETAILS.LABEL.ACCOUNTMANAGER' | translate}}<span class="tx-danger">*</span>
            </label>
            <ng-select [items]="accountManagers" bindLabel="Name" bindValue="Id" formControlName="AccountManagerId" placeholder="{{'SALES.ORDERS.DETAILS.PLACEHOLDER.ACCOUNTMANAGER' | translate}}" [clearable]="false" notFoundText="{{ 'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}"></ng-select>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-6">
            <label class="form-control-label">{{'SALES.ORDERS.DETAILS.LABEL.DELIVERY-METHOD' | translate}}<span class="tx-danger">*</span></label>
            <ng-select [items]="deliveryMethods" bindLabel="Name" bindValue="Id" formControlName="DeliveryMethodId" placeholder="{{'SALES.ORDERS.DETAILS.PLACEHOLDER.DELIVERY-METHOD' | translate}}" [clearable]="false" notFoundText="{{ 'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" (change)="onDeliveryMethodChange($event)"></ng-select>
          </div>
          <div class="col-6">
            <label class="form-control-label">{{'SALES.ORDERS.DETAILS.LABEL.ORDER-REFERENCE' | translate}}<span class="tx-danger"></span></label>
            <input class="form-control" name="Reference" formControlName="Reference" placeholder="{{'SALES.ORDERS.DETAILS.PLACEHOLDER.ORDER-REFERENCE' | translate}}" maxlength="64">
          </div>
        </div>

      </div>

    </div>

  </div>

</form>


<t4-tabs>

  <t4-tab tabTitle="{{'SALES.ORDERS.DETAILS.TABS.TITLE.ORDER-LINES' | translate}}" id="orderlines">

    <div class="object-actions text-right">
      <button (click)="openOrderLinesPriceComponent()" [disabled]="selectedObjects.length == 0" class="btn btn-default" class="btn btn-primary border btn-small" type="button"><i class="far fa-edit"></i>{{'SALES.ORDERS.DETAILS.BUTTON.EDIT-PRICE' | translate}}</button>
      <button (click)="editOrderLines()" [disabled]="selectedObjects.length == 0" class="btn btn-default" class="btn btn-primary border btn-small" type="button"><i class="far fa-edit"></i>{{'SALES.ORDERS.DETAILS.BUTTON.ORDER-EDIT' | translate}}</button>
      <button (click)="changeTicketCustomer()" [disabled]="selectedObjects.length == 0" class="btn btn-default" class="btn btn-primary border btn-small" type="button"><i class="fa fa-address-card"></i>{{'SALES.ORDERS.DETAILS.BUTTON.TICKET-CHANGE-CUSTOMER' | translate}}</button>
    </div>


    <div class="table-responsive confirm-order">

      <table class="rounded table text-dark table-striped clickable">
        <thead>
          <th><input type="checkbox"  (click)="allSelectionToggle($event.currentTarget.checked)" /></th>
          <th>{{'SALES.ORDERS.DETAILS.LISTVIEW.HEADER.PRODUCT' | translate}}</th>
          <th>{{'SALES.ORDERS.DETAILS.LISTVIEW.HEADER.ENTRANCE' | translate}}</th>
          <th>{{'SALES.ORDERS.DETAILS.LISTVIEW.HEADER.TICKET-TEXT' | translate}}</th>
          <th>{{'SALES.ORDERS.DETAILS.LISTVIEW.HEADER.TYPE' | translate}}</th>
          <th>{{'SALES.ORDERS.DETAILS.LISTVIEW.HEADER.SECTION' | translate}}</th>
          <th>{{'SALES.ORDERS.DETAILS.LISTVIEW.HEADER.ROW-SEAT' | translate}}</th>
          <th>{{'SALES.ORDERS.DETAILS.LISTVIEW.HEADER.NAMED' | translate}}</th>
          <th>{{'SALES.ORDERS.DETAILS.LISTVIEW.HEADER.TARIF' | translate}}</th>
          <th class="text-right">{{'SALES.ORDERS.DETAILS.LISTVIEW.HEADER.TOTAL' | translate}}</th>
        </thead>

        <tbody>
          <ng-container *ngFor="let ol of pagedOrderLines">

            <ng-container [ngSwitch]="ol.ProductType">

              <tr (click)="toggleSelection(ol)" *ngSwitchCase="ProductTypeEnum.Ticket">
                <td class=""><input type="checkbox" [checked]="isItemSelected(ol)" /> </td>
                <td *ngIf="ol.PassePartoutName == null">
                  {{ol.EventDate | localDateTime }} - {{ol.EventName}}
                </td>
                <td *ngIf="ol.PassePartoutName != null">{{ ol.PassePartoutName | replaceOnEmpty:''}}</td>
                <td style="width: 150px">{{ ol.SelectedSingleEntranceName | replaceOnEmpty: 'SALES.ORDERS.DETAILS.LISTVIEW.ENTRANCE.DEFAULT-ON-EMPTY' }}</td>
                <td style="width: 200px">{{ ol.TicketText | replaceOnEmpty: 'SALES.ORDERS.DETAILS.LISTVIEW.TICKET-TEXT.DEFAULT-ON-EMPTY' }}</td>
                <td style="width: 150px">{{ol.TicketTypeName}}</td>
                <td style="width: 150px">{{ol.ParentSectionName}}</td>
                <td style="width: 120px">{{ol.RowNumber | replaceOnEmpty}} / {{ol.SeatNumber | replaceOnEmpty}}</td>
                <td style="width: 190px" [ngClass]="{'text-danger': ol.TicketCustomerIsBlacklisted, 'font-weight-bold': ol.TicketCustomerIsBlacklisted}">
                  {{ol.TicketCustomer}}
                  <span *ngIf="ol.TicketCustomerIsBlacklisted"> {{'SALES.ORDERS.DETAILS.LISTVIEW.BLACKLISTED' | translate}}</span>
                </td>
                <td style="width: 130px" [class.tx-danger]="ol.PriceVariationName === null">
                  <mat-select *ngIf="ol.PriceVariationName && ol.PriceVariationName.length > 0; else noPVAvailable;"
                              [value]="ol.PriceVariationId" (click)="onInputFieldClick($event)"
                              (selectionChange)="onPriceVariationChange($event, ol)">
                    <mat-option *ngFor="let variation of ol.PriceVariants" [value]="variation.VariantId" class="select-box-option">{{variation.VariationName}}</mat-option>
                  </mat-select>
                  <ng-template #noPVAvailable>{{ol.PriceVariationName | replaceOnEmpty: 'SALES.ORDERS.DETAILS.LISTVIEW.TARIF.DEFAULT-ON-EMPTY'}}</ng-template>
                </td>
                <td style="width: 105px; text-align: right;">
                  <div (click)="openOrderLinePriceComponent(ol)">{{ol.TotalWoDiscount | currency:'EUR'}}</div>
                </td>
              </tr>
              <tr (click)="toggleSelection(ol)"  *ngSwitchCase="ProductTypeEnum.Membership">

                <td class=""><input type="checkbox" [checked]="isItemSelected(ol)" /> </td>
                <td class="">{{ol.MembershipName}} </td>

                <td colspan="5"></td>

                <td style="width: 190px" [ngClass]="{'text-danger': ol.TicketCustomerIsBlacklisted, 'font-weight-bold': ol.TicketCustomerIsBlacklisted}">
                  {{ol.TicketCustomer}}
                  <span *ngIf="ol.TicketCustomerIsBlacklisted"> {{'SALES.ORDERS.DETAILS.LISTVIEW.BLACKLISTED' | translate}}</span>
                </td>

                <td style="width: 130px" [class.tx-danger]="ol.PriceVariationName === null">
                  <mat-select *ngIf="!ol.PassePartoutId && ol.PriceVariationName && ol.PriceVariationName.length > 0; else noPVAvailable;"
                              [value]="ol.PriceVariationId" (click)="onInputFieldClick($event)"
                              (selectionChange)="onPriceVariationChange($event, ol)">
                    <mat-option *ngFor="let variation of ol.PriceVariants" [value]="variation.VariantId">{{variation.VariationName}}</mat-option>
                  </mat-select>
                  <ng-template #noPVAvailable>{{ol.PriceVariationName | replaceOnEmpty: 'SALES.ORDERS.DETAILS.LISTVIEW.TARIF.DEFAULT-ON-EMPTY'}}</ng-template>
                </td>
                <td style="width: 105px" class="text-right">
                  {{ol.TotalWoDiscount | currency:'EUR'}}
                </td>
              </tr>

              <tr (click)="toggleSelection(ol)"  *ngSwitchCase="ProductTypeEnum.CrossSellProduct">

                <td class=""><input type="checkbox" [checked]="isItemSelected(ol)" /> </td>
                <td class="">{{ol.CrossSellProductName}} - {{ol.CrossSellProductVariantName | translate}}</td>

                <td colspan="6"></td>

                <td style="width: 130px" [class.tx-danger]="ol.PriceVariationName === null">
                  <ng-template #noPVAvailable>{{ol.PriceVariationName | replaceOnEmpty: 'SALES.ORDERS.DETAILS.LISTVIEW.TARIF.DEFAULT-ON-EMPTY'}}</ng-template>
                </td>
                <td style="width: 105px" class="text-right">
                  {{ol.TotalWoDiscount | currency:'EUR'}}
                </td>
              </tr>

              <tr (click)="toggleSelection(ol)" *ngSwitchCase="ProductTypeEnum.DeliveryMethod">
                <td class=""><input type="checkbox" [checked]="isItemSelected(ol)" /> </td>
                <td colspan="8">{{'SALES.ORDERS.DETAILS.LISTVIEW.HEADER.DELIVERY-COST' | translate}}</td>
                <td class="text-right">{{ol.TotalWoDiscount | currency:'EUR'}}</td>
              </tr>

              <tr (click)="toggleSelection(ol)" *ngSwitchCase="ProductTypeEnum.PaymentMethod">
                <td class=""><input type="checkbox" [checked]="isItemSelected(ol)" /> </td>
                <td colspan="8">{{'SALES.ORDERS.DETAILS.LISTVIEW.HEADER.MARKUP-PAYMENT-METHOD' | translate}}</td>
                <td class="text-right">{{ol.TotalWoDiscount | currency:'EUR'}}</td>
              </tr>

              <tr (click)="toggleSelection(ol)" *ngSwitchCase="ProductTypeEnum.MatchDayPass">
                <td class=""><input type="checkbox" [checked]="isItemSelected(ol)" /> </td>
                <td>{{ ol.MatchDayPassName | replaceOnEmpty}}</td>
                <td colspan="3"></td>
                <td>{{ ol.SectorName }}</td>
                <td></td>
                <td>{{ ol.TicketCustomer | replaceOnEmpty}}</td>
                <td>{{ol.PriceVariationName}}</td>
                <td class="text-right">{{ol.TotalWoDiscount | currency:'EUR'}}</td>
              </tr>

              <tr (click)="toggleSelection(ol)" *ngSwitchDefault>
                <td colspan="11">
                  <span class="fas fa-exclamation-triangle"></span>
                  <span class="mx-2">{{'SALES.ORDERS.DETAILS.LISTVIEW.WARNING.CANNOT-SHOW-LINE' | translate}}</span>
                  <span class="fas fa-exclamation-triangle"></span>
                </td>
              </tr>

            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>

    <t4-pager [listCounts]="false" (onPage)="doPage($event)" #pagerComponent></t4-pager>
  </t4-tab>

  <t4-tab tabTitle="{{'SALES.ORDERS.DETAILS.TABS.TITLE.DISCOUNTS' | translate}}" id="discountcodes">

    <div class="object-actions text-right">
      <button (click)="addDiscountCode()" class="btn btn-default" class="btn btn-primary border btn-small" type="button"><i class="far fa-plus-square"></i>{{'SALES.ORDERS.DETAILS.BUTTON.ADDNEW-DISCOUNT' | translate}}</button>
    </div>

    <div class="table-responsive">
      <table class="rounded table text-dark table-striped clickable">
        <thead>
          <th>{{'SALES.ORDERS.DETAILS.LISTVIEW.HEADER.DISCOUNT' | translate}}</th>
          <th class="text-right">{{'SALES.ORDERS.DETAILS.LISTVIEW.HEADER.AMOUNT' | translate}}</th>
        </thead>

        <tbody>
          <ng-container *ngIf="this.pagedDiscountLines.length == 0; else discountlines">
            <tr>
              <td colspan="2">{{'SALES.ORDERS.DETAILS.LISTVIEW.EMPTY-DISCOUNT-LINES' | translate}}</td>
            </tr>
          </ng-container>
          <ng-template #discountlines>
            <tr *ngFor="let d of pagedDiscountLines">
              <td>{{ d.DiscountName | replaceOnEmpty}}</td>
              <td style="width: 80px" class="text-right">{{d.Amount | currency:'EUR'}}</td>
              <td class="grid-action" >
                <button (click)="removeDiscountCode(d.DiscountCodeId)" type="button" class="btn btn-grid btn-round mdi mdi-danger mdi-trash-can-outline"></button>
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>

    <t4-pager [listCounts]="false" (onPage)="doPageForDiscounts($event)" #pagerComponentForDiscounts></t4-pager>
  </t4-tab>

  <t4-tab tabTitle="{{'SALES.ORDERS.DETAILS.TABS.TITLE.MEMBERSHIPS' | translate}}" id="subscriptions">


    <div class="table-responsive">

      <div class="col-lg-3 text-left py-2">
        <ng-select [items]="selectableSubscriptions" bindLabel="Name" bindValue="Id" [clearable]="false" notFoundText="{{ 'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" (change)="onSubscriptionTypeForAllChanged($event)"
                    [class.disabled]="!subscriptionTypes || subscriptionTypes.length === 0 || subscriptionReadOnly"></ng-select>
      </div>

      <table class="rounded table text-dark table-striped clickable">
        <thead>
          <th>{{'SALES.ORDERS.DETAILS.LISTVIEW.HEADER.EVENT-PPT' | translate}}</th>
          <th>{{'SALES.ORDERS.DETAILS.LISTVIEW.HEADER.SECTION' | translate}}</th>
          <th>{{'SALES.ORDERS.DETAILS.LISTVIEW.HEADER.ROW-SEAT' | translate}}</th>
          <th>{{'SALES.ORDERS.DETAILS.LISTVIEW.HEADER.NAMED' | translate}}</th>
          <th>{{'SALES.ORDERS.DETAILS.LISTVIEW.HEADER.MEMBERSHIP' | translate}}</th>
          <th>{{'SALES.ORDERS.DETAILS.LISTVIEW.HEADER.START-DATE' | translate}}</th>
          <th class="text-right">{{'SALES.ORDERS.DETAILS.LISTVIEW.HEADER.TOTAL' | translate}}</th>
        </thead>

        <tbody>
          <tr *ngFor="let ol of pagedSubscriptionLines">

            <!-- <td class=""><input type="checkbox" [checked]="isItemSelected(ol)" /> </td> -->

            <td *ngIf="ol.PassePartoutName == null">
              {{ol.EventDate | localDateTime }} - {{ol.EventName}}
            </td>
            <td *ngIf="ol.PassePartoutName != null">{{ ol.PassePartoutName | replaceOnEmpty:''}}</td>

            <td style="width: 150px">{{ol.ParentSectionName}}</td>
            <td style="width: 120px">{{ol.RowNumber | replaceOnEmpty}} / {{ol.SeatNumber | replaceOnEmpty}}</td>
            <td style="width: 190px">{{ol.TicketCustomer}}</td>

            <td [class.py-1]="!(ol.PassePartoutId === null || !subscriptionTypes || subscriptionTypes.length === 0)">
              <div *ngIf="ol.PassePartoutId === null || !subscriptionTypes || subscriptionTypes.length === 0; else subscriptionTypeDropdown">{{'SALES.ORDERS.DETAILS.LISTVIEW.EMPTY-MEMBERSHIP-LINES' | translate}}</div>
              <ng-template #subscriptionTypeDropdown>
                <ng-select [items]="selectableSubscriptions" bindLabel="Name" bindValue="Id" [clearable]="false" [(ngModel)]="ol.SubscriptionTypeId"
                           notFoundText="{{ 'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" (change)="onSubscriptionTypeChanged($event, ol)"
                           [class.disabled]="ol.PassePartoutId === null || !subscriptionTypes || subscriptionTypes.length === 0 || subscriptionReadOnly"></ng-select>
              </ng-template>

            </td>

            <td>{{getFirstSeasonName(ol)}}</td>
            <td class="text-right">{{ol.Total | currency:'EUR'}}</td>

          </tr>

        </tbody>
      </table>

      <t4-pager [listCounts]="false" (onPage)="doPageForSubscriptions($event)" #pagerComponentForSubscriptions></t4-pager>
    </div>

  </t4-tab>

</t4-tabs>

<div class="mb-3"></div>

<div class="form-layout-footer row">
  <div class="col-6">
    <button [disabled]="saving || validDeliveryMethodSelected == false" (click)="submit()" class="btn btn-block btn-primary bd-0"><i
      class="far fa-save"></i><span>{{'SALES.ORDERS.DETAILS.LABEL.ORDER-SAVE' | translate}}</span></button>
  </div>
  <div class="col-6">
    <button (click)="dialogRef.close(false)" class="btn btn-block btn-secondary bd-0"><i
      class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
  </div>
</div><!-- form-layout-footer -->
