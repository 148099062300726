/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface VenueBasePlanModel { 
    Base64BasePlan?: string | null;
    PanoramaUrl?: string | null;
    PanoramaImageId?: number | null;
    PanoramaImageFileName?: string | null;
    PanoramaImageUrl?: string | null;
}

