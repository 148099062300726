/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EventShopLabelType = 'EventDateTime' | 'EventLocation' | 'AdditionalInfo' | 'Custom';

export const EventShopLabelType = {
    EventDateTime: 'EventDateTime' as EventShopLabelType,
    EventLocation: 'EventLocation' as EventShopLabelType,
    AdditionalInfo: 'AdditionalInfo' as EventShopLabelType,
    Custom: 'Custom' as EventShopLabelType
};

