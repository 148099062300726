/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EventShopLabelView { 
    Id?: number;
    EventId?: number;
    Name?: string | null;
    Code?: string | null;
    IconCode?: string | null;
    DisplayText?: string | null;
    ExternalLink?: string | null;
    Show?: boolean;
    ShowFrom?: Date | null;
    ShowTill?: Date | null;
    IsSystemLabel?: boolean;
    ShopLabelTypeId?: number;
    ShopLabelTypeTranslationCode?: string | null;
}

