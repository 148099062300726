import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogHelper, DialogWidth, HttpLoaderService } from '@tymes4-shared';
import { EditPriceFormComponent } from '../../../../../../../../projects/Backoffice/src/app/shared/dialogs/edit-price-form/edit-price-form.component';
import * as cloneDeep from 'lodash/cloneDeep';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { SnackbarHelper } from '../../../../../../../../projects/Backoffice/src/app/shared/helpers/snackbar-helper';
import { MatchDayPassPriceService } from '../../../../shared/api';

@Component({
  selector: 'app-prices',
  templateUrl: './prices.component.html',
  styleUrls: ['./prices.component.scss']
})
export class PricesComponent implements OnInit {
  @Input()
  set matchDayPassId(id: number) {
    this.mdpId = id
  }
  constructor(private loader: HttpLoaderService,
              private matchdaypassPricesService: MatchDayPassPriceService,
              private dialog: MatDialog,
              private snackbar: SnackbarHelper,
              private elementRef: ElementRef) {
  }

  @ViewChild('listVw') listVw;
  @ViewChild('formElement') formElement;
  @ViewChild('pagerComponent') pagerComponent;

  public listOptions = null;
  public mdpId = null
  public cellWidth: number;
  public isNew = false;
  public saving = false;
  public editDetails = null;
  public alteredPrices = [];
  public isDirty = false;
  public defaultPriceComponent: any = [];
  public pricingGrid:any[][] = null;
  public showPrimaryVariationsOnly = true;
  public startItem = 0;
  public endItem = 19;
  public pageSize = 0;
  public options: string[];
  public filteredOptions: Observable<string[]>;
  public myControl = new FormControl();
  public searchPricingGrid: any[][];
  public searching = false;
  private searchText = '';
  public currentSectors = null;
  public currentPage = 1;
  public allPriceCategories = null;
  public pricesCopy = null
  public widthCalculated = false

  validationMessages = [];

  calcWidth() {
    if(this.widthCalculated || this.editDetails === null) return
    const element = this.elementRef.nativeElement;

    if(element.offsetParent !== null) {
      this.widthCalculated = true
      let columnCount = this.editDetails.PriceVariations.length ;

      if (this.showPrimaryVariationsOnly) {
        const filtered = this.editDetails.PriceVariations.filter(pv => pv.IsPrimary);
        columnCount = filtered.length;
      }
      columnCount += 1;
      this.cellWidth =  (this.formElement.nativeElement.offsetWidth - 40) / columnCount;
    }
  }

  ngAfterContentChecked() {
    this.calcWidth()
  }

  getPriceDetails() {
    if(!this.mdpId) return
    this.loader.open();
    if(this.editDetails) {
      this.editDetails = null
    }
    this.matchdaypassPricesService.getMDPPricesForEditing(this.mdpId).subscribe((details: any) => {
      this.editDetails = details;
      this.pricesCopy = JSON.parse(JSON.stringify(details.Prices))

      this.matchdaypassPricesService.getEmptyComponentViewForMDP(this.mdpId).subscribe(result => {
        this.defaultPriceComponent = result;
      });
      this.initPricingGrid()
      this.loader.close();
      this.calcWidth()
    });
  }

  ngOnInit() {
    this.getPriceDetails()
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  initPricingGrid() {
      if (this.editDetails === null) return;
      this.pricingGrid = [];
      this.currentSectors = this.editDetails.Sectors;

      for (let sector of this.editDetails.Sectors) {

        let sectorPrices = [];

        for (let pv of this.editDetails.PriceVariations) {
          var price = this.getCombinationPrice(sector, pv, true);
          sectorPrices.push(price);
        }
        this.pricingGrid.push([sector.Id, sectorPrices]);
      }


      this.pagerComponent.setObjectCount(this.editDetails.Sectors.length);

      this.pagerComponent.setPage(this.currentPage);

      this.options = this.editDetails.Sectors.map(pricing => pricing.Name);
      this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
      if(this.searchText !== '') {
        this.search(this.searchText);
      }
  }

  doPage(ageArgs: any) {
    this.currentPage = ageArgs.pageNr;
    this.startItem = (ageArgs.pageNr - 1) * 20;
    this.endItem = (ageArgs.pageNr) * 20;
    this.pagerComponent.setPage(ageArgs.pageNr);
  }

  submit() {
    this.loader.open();
    const pl = {
      MatchDayPassId: this.mdpId,
      Prices: this.editDetails.Prices
    };

    this.matchdaypassPricesService.updateMDPPriceList(pl).subscribe((data: any) => {
      this.isDirty = false;
      this.loader.close();
      this.snackbar.open('De wijzigingen zijn opgeslagen');
      this.initPricingGrid();
      this.pricesCopy = JSON.parse(JSON.stringify(this.editDetails.Prices))
    });
  }

  trackByVar(index, item) {
    return item.Id;
  }

  trackByCat(index, item) {
    return item.Id;
  }

  getCombinationPrice(sector, variation, isVatBox) {
    if (this.editDetails.Prices === null || this.editDetails.Prices.length === 0) {
      return '';
    }

    const price = this.findPrice(sector.Id, variation.Id);
    if (price != null) {
      if (isVatBox) {
        return price.PriceTotal;
      } else {
        return price.Price;
      }
    } else {
      return '';
    }
  }

  isMultipleOf(multiple, index) {
    return (index % multiple === 0);
  }

  openOrderLinePriceComponent(sector, variation) {
    let price = this.findPrice(sector.Id, variation.Id);
    let isNew = false;

    if (price === null) {
      price = cloneDeep(this.defaultPriceComponent);
      price.SectorId = sector.Id;
      price.PriceVariationId = variation.Id;
      isNew = true;
    }

    const title = `Bewerken prijs opbouw`;
    const options = DialogHelper.GetDialogOptions(DialogWidth.md, { allowEmptyRate: true, title: title, readOnly: false, priceLineComponentSelections: price.Components });
    const dialogRef = this.dialog.open(EditPriceFormComponent, options);

    dialogRef.afterClosed()
      .subscribe(lines => {

        let reconstruct = false;


        if (lines === false) {
          //canceled
        }
        else if (lines === null) {
          //delete this price
          let toRemove = this.findPrice(sector.Id, variation.Id);
          if (toRemove) {
            this.editDetails.Prices.splice(this.editDetails.Prices.indexOf(toRemove), 1);
          }
          this.isDirty = true;
          reconstruct = true;
        }
        else {
          // store data and update price

          price.Components = lines;
          price.Price = lines.reduce((prev, curr) => +prev + +curr.AmountExVAT, 0);;
          price.PriceTotal = lines.reduce((prev, curr) => +prev + +curr.AmountInVAT, 0);;


          if (isNew) {
            this.editDetails.Prices.push(price);
          }

          this.isDirty = true;
          reconstruct = true;
        }

        if (reconstruct) {
          this.initPricingGrid();
        }
      });
  }

  actionClick(action: string) {
    if (action === 'cancel') {
      this.editDetails.Prices = JSON.parse(JSON.stringify(this.pricesCopy))
      this.isDirty = false;
      this.initPricingGrid()
    }
  }

  findPrice(sectorId, variationId) {
    const filtered = this.editDetails.Prices.filter(function (p)   {
      if (p.SectorId === sectorId && p.PriceVariationId === variationId) {
        return p;
      }
    });

    if (filtered.length > 0) {
      return filtered[0];
    }
    return null;
  }

  search(event: any) {

    let text = '';
    if(typeof event ===  'string') {
      text = event;
    }
    else {
      text = event.target.value;
    }

    this.searchText = text;
    const priceCat = this.editDetails.Sectors.filter(pricing => pricing.Name.toLowerCase().includes(text.toLowerCase()));
    this.currentSectors = priceCat;
    if(priceCat != null) {
      this.searchPricingGrid = [];
      for (let sector of priceCat) {
        this.searchPricingGrid.push(this.pricingGrid.filter(pricing => pricing[0] === sector.Id));
      }
      this.searching = true;
      this.pagerComponent.setObjectCount(this.currentSectors.length);
      this.pagerComponent.setPage(1);
      this.startItem = 0;
      this.endItem = 19;
    } else {
      this.currentSectors = this.editDetails.currentSectors;
      this.pagerComponent.setObjectCount(this.currentSectors.length);
      this.pagerComponent.setPage(1);
      this.searching = false;
      }
  }
}
