/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateEventPlacementArgs { 
    Id?: number;
    Name?: string | null;
    PriceCategoryId?: number | null;
    Version?: string | null;
    SectionNr?: string | null;
    ShortName?: string | null;
    SaleCategoryId?: number | null;
    StadiumPlanSectionId?: number | null;
    TicketNamingType?: number | null;
    PanoramaUrl?: string | null;
    PanoramaImageId?: number | null;
}

