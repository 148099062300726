


import {Component, OnInit, Inject, ViewChild, AfterViewInit} from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {LoggingService} from '@tymes4-shared';
import { HttpLoaderService } from '@tymes4-shared';

@Component({
  selector: 'app-print-pdf-component',
  templateUrl: './print-pdf.component.html',
  styleUrls: ['./print-pdf.component.scss']
})
export class PrintPdfComponent implements OnInit, AfterViewInit {

  @ViewChild('pdfFrame') pdfFrame;

  public form: FormGroup;
  public saving = false;

  private editingObject: any = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<PrintPdfComponent>,
    private logging : LoggingService
  ) { }


  ngOnInit() {
    // setTimeout(() => {
    //
    //
    // });




    // var doc = this.pdfFrame.nativeElement.contentWindow.document;
    // doc.open();
    // doc.write(this.passedData.blob);
    // doc.close();
  }

  ngAfterViewInit() {
    const blobUrl = URL.createObjectURL(this.passedData.blob);

    this.pdfFrame.nativeElement.src = blobUrl;
    var newHeight = this.pdfFrame.nativeElement.parentElement.getBoundingClientRect().height - 70;
    this.pdfFrame.nativeElement.height = newHeight + "px";
  }


}
