import {ErrorHandler, Inject, Injectable, Injector} from '@angular/core';
import {Router} from '@angular/router';
import {EnvironmentService, ApiDetails, NotFoundError, BadDataError, HttpError, UnauthorizedError, SnackbarService, HttpLoaderService} from '@tymes4-shared';
import { BASE_BO_CONFIG } from '../../../injectors';
import { BackofficeConfigModel } from '../../api';

@Injectable()
export class HttpErrorHandler implements ErrorHandler {
  private environment: ApiDetails;
  constructor(private injector: Injector, @Inject(BASE_BO_CONFIG) public config: BackofficeConfigModel,) {
  }

  handleError(error) {
    if (this.config.enableErrorPage) {
      throw error;
    }

    // need to call manually in the errorhandler
    const router = this.injector.get(Router);

    if (error != null) {
      if (error.originalError != null) {
        if (error.originalError.status == 400) {
          router.navigate(['/sessions/error', {errorDetails: 'Bad request'}]);
        }
        else if (error.originalError.status == 401) {
          router.navigate(['/sessions/access-denied']); //will redirect to login
        }
        else if (error.originalError.status == 404) {
          router.navigate(['/sessions/not-found']);
        }
        else if (error.originalError.status == 500) {
          //Service error
          this.handleUnknownError(error, error.originalError.error.Message);
        }
        else {
            this.handleUnknownError(error, null);
        }
      } else {
        if (error instanceof NotFoundError) {
          router.navigate(['/sessions/not-found']);
        }
        else if (error instanceof UnauthorizedError) {
          router.navigate(['/sessions/access-denied']); //will redirect to login
        }
        else if (error instanceof BadDataError) {
          this.handleUnknownError(error, null);
        }
        else if (error instanceof HttpError) {
          this.handleUnknownError(error, null);
        }
      }
    }
  }

  handleUnknownError(error: any, serverMsg: string) {

    const snackbar = this.injector.get(SnackbarService);

    let message = "An unknown error has occurred while processing the request. Please reload the page. ";

    if (serverMsg !== null) {
      message = serverMsg + " Please reload the page.";
    }

    let snackBarRef = snackbar.error(message);

    snackBarRef.onAction().subscribe(() => {
      window.location.reload();
    });
  }
}


