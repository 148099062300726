import { Injectable } from '@angular/core';
import { MsalModule, MsalService, MsalGuardConfiguration, MsalInterceptorConfiguration, MsalBroadcastService } from '@azure/msal-angular';
import { PublicClientApplication, BrowserCacheLocation, LogLevel, Configuration } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root'
})
export class MsalConfigService {
  constructor() {}

  // Create a minimal, disabled MSAL config
  disabledMsalConfig: Configuration = {
    auth: {
      clientId: '', // No clientId or any other required fields
      authority: '', // No authority
      redirectUri: '', // No redirect URI
    },
    cache: {
      cacheLocation: 'localStorage', // Or 'sessionStorage' if preferred
      storeAuthStateInCookie: false, // No cookies will be used
    }
  };

  getMsalConfig(): Configuration | null {
    
    const rawConfig = sessionStorage.getItem('entra');

    if (!rawConfig) 
      return this.disabledMsalConfig; //entra disabled 
    

    try {
      const entraConfig = JSON.parse(rawConfig);

      if (!entraConfig || !entraConfig.Enabled) 
        return this.disabledMsalConfig; //entra disabled

      return {
        auth: {
          clientId: entraConfig.ClientId,
          authority: entraConfig.Authority,
          redirectUri: entraConfig.RedirectUri,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.SessionStorage,
          storeAuthStateInCookie: false
        },
        system: {
          loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
              if (containsPii) return;
              console.log(message);
            },
            logLevel: LogLevel.Info
          }
        }
      };
    } catch (error) {
      return this.disabledMsalConfig; //disable entra
    }
  }
}