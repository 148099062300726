// Third party JS imports
// Page loader
import './vendor/pace/pace.min.js';
// // User tour
// import '../node_modules/hopscotch/dist/js/hopscotch.min.js';
// // Charts
// import './vendor/Chart.min.js';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { BASE_BO_CONFIG } from './app/injectors';
import { enableProdMode } from '@angular/core';

if (environment.production) {
  enableProdMode();
}

async function main() {

    try {
      const url = environment.configApiURL + 'config/' + window.location.hostname;
      const data = await fetch(url);
      const config = await data.json(); 

      if (config.Config.EntraConfig)
        sessionStorage.setItem('entra', JSON.stringify(config.Config.EntraConfig)); 

      sessionStorage.setItem('endpoint', config.Config.apiURL);
      sessionStorage.setItem('ticketShopURL', config.Config.ticketShopURL);
      sessionStorage.setItem('phoneValidation', config.Config.PhoneValidation);

      //store the state if we don't already have one.
      if (sessionStorage.getItem('state') === null && config.State !== null) {
        //no state, store a new one. We only do this once, otherwise the shopper will get a new session upon every page refresh
        sessionStorage.setItem('state', config.State);
      }

      await platformBrowserDynamic([{ provide: BASE_BO_CONFIG, useValue: config.Config }]).bootstrapModule(AppModule);
    }
    catch (error) {
      console.error(error);
      window.location.href = "unavailable.html";
    }
}

main();
