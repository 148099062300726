/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrderTypeEnum } from './orderTypeEnum';


export interface Order { 
    Id?: number;
    Created?: Date | null;
    Modified?: Date | null;
    Deleted?: Date | null;
    CustomerId?: number;
    OrderDate?: Date;
    Reference?: string | null;
    TotalExVat?: number;
    TotalInclVaT?: number;
    VAT?: number;
    ModificationForOrderId?: number | null;
    Payed?: boolean;
    IsProlongationOrder?: boolean;
    IsReservationOrder?: boolean;
    Locked?: boolean;
    DeliveryMethodId?: number | null;
    PayByInvoice?: boolean;
    SalesChannelId?: number;
    InitiativeId?: number | null;
    AccountManagerId?: number | null;
    CanceledByCustomer?: boolean | null;
    CanceledByUserId?: number | null;
    PendingCancelByCustomer?: boolean;
    IncludeInInvoiceBatch?: boolean;
    ContactId?: number | null;
    OrderType?: OrderTypeEnum;
    OrderProducts?: string | null;
    PaymentReference?: number | null;
    IsCredited?: boolean;
}

