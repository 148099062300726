import {Injectable, OnInit} from '@angular/core';

import {Router} from '@angular/router';
import {CacheRegisterState, CashRegisterAction} from '../models/cache-register-state';
import {LoggingService} from '@tymes4-shared';

@Injectable()
export class CashRegisterStateService implements OnInit {

  constructor(private logging: LoggingService, private router: Router) {
    if (this.get() === null) {
      this.reset();
    }
  }

  ngOnInit() {
    if (this.get() === null) {
      this.reset();
    }
  }

  public reset() {
    let state = new CacheRegisterState();
    state.currentAction = CashRegisterAction.NotSet;
    state.selectedCustomers = [];
    this.set(state);
  }

  public set(cacheRegisterState: CacheRegisterState) {
    sessionStorage.setItem('cache-register-state', JSON.stringify(cacheRegisterState));
  }

  public get(): CacheRegisterState {
    if (sessionStorage.getItem('cache-register-state') === null) return null;

    let u: CacheRegisterState = new CacheRegisterState();
    var d = JSON.parse(sessionStorage.getItem('cache-register-state'))
    return u.deserialize(d);
  }

  ///This method only does something when we are in the cashregister orderprocess
  performCashRegisterAfterOrderProcess(orderId): any {
    var state = this.get();
    if (state.currentAction === CashRegisterAction.OrderingStarted) {
      //this means the order is started from the cashregister, perform a payment
      state.currentAction = CashRegisterAction.OrderPaymentRequired;
      state.placedOrderId = orderId;
      this.set(state);

      this.router.navigate(['/sales/cashregister/dashboard']);
    }
  }
}





