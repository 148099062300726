<div class="row modal-bar">
    <div class="col-sm-4">
        <h5 class="modal-title">{{'SALES.ORDERS.REVISIONS.DETAILS.TITLE' | translate : {revisionId: revision.Id} }}</h5>
    </div>

    <div class="d-flex align-items-end m-auto col-sm-2 justify-content-end">
        <span class="pr-12">
            <button *ngIf="this.passedData.revisions[this.selectedIndex+2]" class="btn btn-warning navigation-button" (click)="changeRevision('prev')">
                <i class="fas fa-arrow-alt-circle-left"></i>
                {{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.MUTATION.PREVIOUS' | translate | uppercase}}
            </button>
        </span>
    </div>
    <div class="d-flex align-items-start m-auto col-sm-2">
        <span class="pl-12">
            <button *ngIf="this.passedData.revisions[this.selectedIndex-1]" class="btn btn-warning navigation-button" (click)="changeRevision('next')">
                <i class="fas fa-arrow-alt-circle-right"></i>
                {{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.MUTATION.NEXT' | translate | uppercase}}
            </button>
        </span>
    </div>

    <div class="col-sm-4">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
</div>

<div class="d-flex flex-row row">
    <div class="d-flex flex-row w-100 p-15">
        <div class="d-flex flex-column flex-fill w-50">
            <div class="d-flex info-highlight mt-3">
                <div class="flex-row w-100">
                    <div class="row w-100 mt-0">
                        <div class="col-12 w-100 d-inline-flex align-items-center">
                            <div class="d-flex align-items-center">
                                <span class="pl-12">
                                    {{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.SITUATION-BEFORE' | translate | uppercase}} 
                                    <span class="mutation-header">{{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.MUTATION' | translate | uppercase}} #{{original.Id}}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row w-100 mt-0">
                        <div class="col-12 w-100 d-inline-flex align-items-center">
                            <div class="d-flex align-items-center">
                                <i class="fas fa-user"></i>
                                <span class="pl-12">
                                    <p class="info-label">{{'T4.GENERIC.INTHENAMEOF' | translate | uppercase}}</p>
                                    <p class="info-data">
                                        <span *ngIf="original.CustomerCalculatedName">
                                            <t4-customer-identification-control [isIbaActive]="isIbaActive" [showCustomerNumber]="true" [customer]="constructCustomerIdentificationModel(original)"></t4-customer-identification-control>
                                        </span>
                                        <span *ngIf="original.CustomerCalculatedName == undefined">
                                            {{'SALES-ENTITY-MODIFICATION.LISTVIEW.CHECK.UNEDITED' | translate}}
                                        </span>
                                    </p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-12 w-100 d-inline-flex align-items-center">
                            <div class="d-flex align-items-center">
                                <i class="fas fa-chair"></i>
                                <span class="pl-12">
                                    <p class="info-label">{{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.POSITION' | translate | uppercase}}</p>
                                    <p class="info-data">

                                        <span *ngIf="original.Section">
                                            {{original.Section ? original.Section : '-'}} / {{original.RowNumber | replaceOnEmpty}} / {{original.SeatNumber | replaceOnEmpty}}
                                        </span>
                                        <span *ngIf="original.Section == undefined">
                                            {{'SALES-ENTITY-MODIFICATION.LISTVIEW.CHECK.UNEDITED' | translate}}
                                        </span>
                                    </p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-12 w-100 d-inline-flex align-items-center">
                            <div class="d-flex align-items-center">
                                <i class="fas fa-door-closed"></i>
                                <span class="pl-12">
                                    <p class="info-label">{{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.ENTRANCE' | translate | uppercase}}</p>
                                    <p class="info-data">
                                        <span>
                                            {{original.EntranceName | replaceOnEmpty:'SALES-ENTITY-MODIFICATION.LISTVIEW.CHECK.UNEDITED' | translate}}
                                        </span>
                                    </p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <hr class="row w-100">
                    <div class="row w-100">
                        <div class="col-11 w-100 d-inline-flex align-items-center">
                            <div class="d-flex align-items-center">
                                <i class="fas fa-credit-card"></i>
                                <span class="pl-12">
                                    <p class="info-label">
                                        <span>{{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.PRICE-MUTATIONS' | translate | uppercase}}</span>
                                    </p>
                                    <p class="d-inline-flex w-100" *ngIf="original.NewPrice">
                                        {{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.ORIGNAL-PRICE' | translate | uppercase}} {{original.NewPrice | currency:'EUR' | replaceOnEmpty}}
                                    </p>
                                    <p *ngIf="!original.NewPrice">
                                        {{'SALES-ENTITY-MODIFICATION.LISTVIEW.CHECK.UNEDITED' | translate}}
                                    </p>
                                </span>
                            </div>
                        </div>
                        <div class="col-1 w-100 d-flex align-items-center justify-content-center">
                            <button class="btn btn-warning rounded-circle" (click)="toggleOriginalPriceHover();">
                                <i class="fas" [ngClass]="showOriginalPriceHover ? 'fa-angle-up' : 'fa-angle-down'"></i>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="showOriginalPriceHover" class="justify-content-end w-100 d-flex">
                        <div class="card card-body price-hover row flex-row position-absolute">
	                        <div class="col-6">
	                            {{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.PRICE.ORIGINAL-CATEGORY' | translate | uppercase}}
	                        </div>
	                        <div class="col-6 justify-content-end text-right font-weight-bold">
	                            {{original.PriceVariationName | replaceOnEmpty:'SALES-ENTITY-MODIFICATION.LISTVIEW.CHECK.UNEDITED' | translate}}
	                        </div>
	                        <div class="col-6">
	                            {{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.PRICE.ORIGINAL-PRICE' | translate | uppercase}}
	                        </div>
	                        <div class="col-6 justify-content-end text-right font-weight-bold">
	                            <span *ngIf="original.NewPrice != null">
	                                {{original.NewPrice | currency:'EUR' | replaceOnEmpty}} <i *ngIf="original.ManualPrice != null">[{{'ADMINISTRATION.SYSTEM-RESOURCES.MANUALLY' | translate | uppercase}}]</i>
	                            </span>
	                            <span *ngIf="original.NewPrice == null">{{'SALES-ENTITY-MODIFICATION.LISTVIEW.CHECK.UNEDITED' | translate}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-12 w-100 d-inline-flex align-items-center">
                            <div class="d-flex align-items-center">
                                <i class="fas fa-cash-register"></i>
                                <span class="pl-12">
                                    <p class="info-data">{{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.REST-ACTION' | translate | uppercase}}</p>
                                </span>
                                <!-- TODO: add line for chosen action -->
                            </div>
                        </div>
                    </div>
                    <hr class="row w-100">
                    <div class="row w-100">
                        <div class="col-11 w-100 d-inline-flex align-items-center">
                            <div class="d-flex align-items-center">
                                <i class="fas fa-barcode"></i>
                                <span class="pl-12">
                                    <p class="info-data">{{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.NEW-BARCODE' | translate | uppercase}}</p>
                                </span>
                            </div>
                        </div>
                        <div class="col-1 w-100 d-inline-flex align-items-center">
                            <span>{{original.NewBarcode ? translate.instant('GENERIC.BOOLEANTYPE.YES') : translate.instant('GENERIC.BOOLEANTYPE.NO')}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column arrow p-0">
            <div class="d-flex mt-3 pt-16">
                <div class="flex-row w-100">
                    <div class="row w-100 mt-0">
                        <div class="w-100 d-flex"></div>
                    </div>
                </div>
            </div>
            <div class="d-flex row w-100 mt-0">
                <div class="flex-row w-100">
                    <div class="row w-100 mt-0">
                        <div class="w-100 d-flex align-items-center justify-content-center" *ngIf="(revision.CustomerId)">
                            <i class="fas fa-arrow-alt-circle-right"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex row w-100 m-0">
                <div class="flex-row w-100">
                    <div class="row w-100 mt-0">
                        <div class="w-100 d-flex align-items-center justify-content-center" *ngIf="(revision.EventPlacementId || revision.CustomerPassePartoutId)"> 
                            <i class="fas fa-arrow-alt-circle-right"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex row w-100">
                <div class="flex-row w-100">
                    <div class="row w-100 mt-0">
                        <div class="w-100 d-flex align-items-center justify-content-center" *ngIf="(revision.EntranceId)">
                            <i class="fas fa-arrow-alt-circle-right"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex row w-100">
                <div class="flex-row w-100">
                    <div class="row w-100 mt-0">
                        <div class="w-100 d-flex align-items-center justify-content-center" *ngIf="(revision.ManualPrice || revision.AppliedCredit || revision.PriceVariationId)">
                            <i class="fas fa-arrow-alt-circle-right"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex row w-100 mt-0">
                <div class="flex-row w-100">
                    <div class="row w-100 mt-0">
                        <div class="w-100 d-flex"></div>
                    </div>
                </div>
            </div>
            <div class="d-flex row w-100">
                <div class="flex-row w-100">
                    <div class="row w-100 mt-0">
                        <div class="w-100 d-flex align-items-center justify-content-center" *ngIf="(revision.NewBarcode)">
                            <i class="fas fa-arrow-alt-circle-right"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column flex-fill w-50">
            <div class="d-flex info-highlight mt-3">
                <div class="flex-row w-100">
                    <div class="row w-100 mt-0">
                        <div class="col-12 w-100 d-inline-flex align-items-center">
                            <div class="d-flex align-items-center">
                                <span class="pl-12">
                                    {{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.SITUATION-AFTER' | translate | uppercase}} 
                                    <span class="mutation-header">{{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.MUTATION' | translate | uppercase}} #{{revision.Id}}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row w-100 mt-0">
                        <div class="col-12 w-100 d-inline-flex align-items-center">
                            <div class="d-flex align-items-center">
                                <i class="fas fa-user"></i>
                                <span class="pl-12">
                                    <p class="info-label">{{'T4.GENERIC.INTHENAMEOF' | translate | uppercase}}</p>
                                    <p class="info-data">
                                        <span *ngIf="revision.CustomerCalculatedName">
                                            <t4-customer-identification-control [isIbaActive]="isIbaActive" [showCustomerNumber]="true" [customer]="constructCustomerIdentificationModel(revision)"></t4-customer-identification-control>
                                        </span>
                                        <span *ngIf="revision.CustomerCalculatedName == undefined">
                                            {{'SALES-ENTITY-MODIFICATION.LISTVIEW.CHECK.UNEDITED' | translate}}
                                        </span>
                                    </p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-12 w-100 d-inline-flex align-items-center">
                            <div class="d-flex align-items-center">
                                <i class="fas fa-chair"></i>
                                <span class="pl-12">
                                    <p class="info-label">{{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.POSITION' | translate | uppercase}}</p>
                                    <p class="info-data">
                                        <span *ngIf="revision.Section">
                                            {{revision.Section ? revision.Section : '-'}} / {{revision.RowNumber | replaceOnEmpty}} / {{revision.SeatNumber | replaceOnEmpty}}
                                        </span>
                                        <span *ngIf="revision.Section == undefined">
                                            {{'SALES-ENTITY-MODIFICATION.LISTVIEW.CHECK.UNEDITED' | translate}}
                                        </span>
                                    </p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-12 w-100 d-inline-flex align-items-center">
                            <div class="d-flex align-items-center">
                                <i class="fas fa-door-closed"></i>
                                <span class="pl-12">
                                    <p class="info-label">{{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.ENTRANCE' | translate | uppercase}}</p>
                                    <p class="info-data">
                                        <span>
                                            {{revision.EntranceName | replaceOnEmpty:'SALES-ENTITY-MODIFICATION.LISTVIEW.CHECK.UNEDITED' | translate}}
                                        </span>
                                    </p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <hr class="row w-100">
                    <div class="row w-100">
                        <div class="col-11 w-100 d-inline-flex align-items-center">
                            <div class="d-flex align-items-center w-100">
                                <i class="fas fa-credit-card"></i>
                                <span class="pl-12 w-100">
                                    <p class="info-label">
                                        <span>{{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.PRICE-MUTATIONS' | translate | uppercase}}</span>
                                    </p>
                                    <p class="d-inline-flex w-100" *ngIf="revision.NewPrice">
                                        {{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.ORIGNAL-PRICE' | translate | uppercase}} {{original.NewPrice | currency:'EUR' | replaceOnEmpty}}
                                        <span class="flex-grow-1 text-center">></span>
                                        <span class="font-weight-bold">{{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.NEW-PRICE' | translate | uppercase}} {{revision.NewPrice | currency:'EUR' | replaceOnEmpty}}</span>
                                    </p>
                                    <p *ngIf="!revision.NewPrice">
                                        {{'SALES-ENTITY-MODIFICATION.LISTVIEW.CHECK.UNEDITED' | translate}}
                                    </p>
                                </span>
                            </div>
                        </div>
                        <div class="col-1 w-100 d-flex align-items-center justify-content-center">
                            <button class="btn btn-warning rounded-circle" (click)="toggleRevisionPriceHover();">
                                <i class="fas" [ngClass]="showRevisionPriceHover ? 'fa-angle-up' : 'fa-angle-down'"></i>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="showRevisionPriceHover" class="justify-content-end w-100 d-flex">
                        <div class="card card-body price-hover row flex-row position-fixed m-0">
                            <div class="col-6">
	                            {{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.PRICE.CATEGORY' | translate | uppercase}}
	                        </div>
	                        <div class="col-6 justify-content-end text-right font-weight-bold">
	                            {{revision.PriceVariationName | replaceOnEmpty:'SALES-ENTITY-MODIFICATION.LISTVIEW.CHECK.UNEDITED' | translate}}
	                        </div>
	                        <div class="col-6">
	                            {{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.PRICE.NEW-PRICE' | translate | uppercase}}
	                        </div>
	                        <div class="col-6 justify-content-end text-right font-weight-bold">
	                            <span *ngIf="revision.NewPrice != null">
	                                {{revision.NewPrice | currency:'EUR' | replaceOnEmpty}} <i>[{{'ADMINISTRATION.SYSTEM-RESOURCES.MANUALLY' | translate | uppercase}}]</i>
	                            </span>
	                            <span *ngIf="revision.NewPrice == null">{{'SALES-ENTITY-MODIFICATION.LISTVIEW.CHECK.UNEDITED' | translate}}</span>
	                        </div>
	                        <div class="col-6">
	                            {{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.PRICE.BALANCE' | translate | uppercase}}
	                        </div>
	                        <div class="col-6 justify-content-end text-right font-weight-bold">
	                            {{calculateLineBalance() | currency:'EUR' | replaceOnEmpty:'SALES-ENTITY-MODIFICATION.LISTVIEW.CHECK.UNEDITED'}}
	                        </div>
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-12 w-100 d-inline-flex align-items-center">
                            <div class="d-flex align-items-center">
                                <i class="fas fa-cash-register"></i>
                                <span class="pl-12">
                                    <p class="info-label">{{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.REST-ACTION' | translate | uppercase}}</p>
                                </span>
                                <!-- TODO: add line for chosen action -->
                            </div>
                        </div>
                    </div>
                    <hr class="row w-100">
                    <div class="row w-100">
                        <div class="col-11 w-100 d-inline-flex align-items-center">
                            <div class="d-flex align-items-center">
                                <i class="fas fa-barcode"></i>
                                <span class="pl-12">
                                    <p class="info-label">{{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.GENERATE-NEW-BARCODE' | translate | uppercase}}</p>
                                </span>
                            </div>
                        </div>
                        <div class="col-1 w-100 d-inline-flex align-items-center justify-content-center">
                            <span class="info-label">
                                {{revision.NewBarcode ? translate.instant('GENERIC.BOOLEANTYPE.YES') : translate.instant('GENERIC.BOOLEANTYPE.NO')}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="d-flex flex-row w-100 p-15">
        <div class="d-flex flex-column flex-fill w-50">
            <div class="info-highlight mt-3">
                <div class="w-100">
                    <div class="w-100 mt-0">
                        <p class="info-data title">{{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.COMMENT' | translate | uppercase}}</p>
                        <textarea #comment disabled class="w-100 comment-area" [(ngModel)]="revision.Comment"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column arrow p-0">
            <div class="d-flex mt-3 p-16">
                <div class="flex-row w-100">
                    <div class="row w-100 mt-0">
                        <div class="w-100 d-flex"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column flex-fill mt-3 w-50">
            <p class="info-data mt-3 title">{{'SALES.ORDERS.OVERVIEW.LISTVIEW.HEADER.SUMMARY' | translate | uppercase}}</p>
            <div class="info-highlight flex-row flex-wrap h-100">
                <div class="w-100 mt-0 d-inline-flex align-items-center">
                    <i class="fas fa-edit info-label"></i>
                    <span class="">
                        <p class="info-label" *ngIf="numberOfChanges > 0">{{'SALES.ORDERS.OVERVIEW.LISTVIEW.HEADER.CHANGES' | translate : {noOfChanges : numberOfChanges} }}</p>
                        <p class="info-label" *ngIf="numberOfChanges == 0">{{'SALES.ORDERS.OVERVIEW.LISTVIEW.HEADER.NOCHANGES' | translate}}</p>
                    </span>
                </div>
                <div class="w-100 d-inline-flex align-items-center" *ngIf="revision.NewBarcode">
                    <i class="fas fa-barcode info-label"></i>
                    <span class="">
                        <p class="info-label">{{'SALES.ORDERS.OVERVIEW.LISTVIEW.HEADER.NEW-BARCODE' | translate}}</p>
                    </span>
                </div>
                <div class="w-100 d-inline-flex align-items-center">
                    <i class="fas fa-user info-label"></i>
                    <span class="">
                        <p class="info-label">{{'SALES.ORDERS.OVERVIEW.LISTVIEW.HEADER.CHANGES-MADE-BY' | translate : {user: revision.UserCalculatedName} }}</p>
                    </span>
                </div>
                <div class="w-100 d-inline-flex align-items-center">
                    <i class="fas fa-calendar info-label"></i>
                    <span class="">
                        <p class="info-label">{{'SALES.ORDERS.OVERVIEW.LISTVIEW.HEADER.CHANGES-MADE-ON' | translate}} {{date}} | {{localTime}}</p>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
