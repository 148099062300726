/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AddressModel } from './addressModel';


export interface RegisterContactModel { 
    Email?: string | null;
    Initials?: string | null;
    FirstName: string;
    MiddleName?: string | null;
    LastName: string;
    DateOfBirth: Date;
    Gender: string;
    MobileNr?: string | null;
    CustomerType?: number;
    CompanyName?: string | null;
    Address?: AddressModel;
    ExternalId?: string | null;
    CustomerNumber?: string | null;
    OptInQuestionResults?: string | null;
    PreRegisterKey?: string | null;
    PendingOrderUID?: string | null;
    PendingOrderId?: number | null;
    LanguageCode?: string | null;
    IsManagedByParent?: boolean | null;
}

