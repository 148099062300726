
import {debounceTime} from 'rxjs/operators';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { ConstService } from '../../services/const.service';
import {LoggingService} from '@tymes4-shared';

import { FileService, HttpLoaderService } from '@tymes4-shared';
import {VbbUpdateEpDialogComponent} from '../vbb-update-ep-dialog/vbb-update-ep-dialog.component';
import { VBBValidator } from './vbb-validator';
import { HelperService } from '../../components/common/helper-service';
import {ConfirmService} from '@tymes4-shared';
import {FileInputDialogComponent} from "../file-input-dialog/file-input-dialog.component";
import {VenueSvgInputDialogComponent} from '../venue-svg-input-dialog/venue-svg-input-dialog.component';
import {TranslateService} from '@ngx-translate/core'
import * as moment from 'moment';
import { EventPlacementService, ImageService, ImageTypeEnum, SaleCategoryService, StadiumPlanSectionService, UpdateEventPlacementArgs, VenueBuildingBlockService } from '../../api';
import { Observable } from 'rxjs-compat/Observable';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-edit-vbb-form',
  templateUrl: './edit-vbb-form.component.html'
})
export class EditVBBFormComponent implements OnInit {

  ImageTypeEnum = ImageTypeEnum;

  validationMessages = [];

  public form: FormGroup;

  private editingObject = null;
  private isNew = false;
  public saving = false;

  private venueParentControl: FormControl;
  public images: any = [];
  public panoramaImages: any = [];
  public color: any;
  public isInArchiveMode = this.authService.isInArchiveMode();

  //*region Option Values
  public priceCategories =  null;
  public stadiumPlanSections =  [];
  public saleCategories = null;
  public filteredParentVenues: any;
  public ctrlStadiumPlanSectionId: FormControl;
  public ticketNamingType : any = [];

 // private eventPlacementEventId: number; //when this is set it means we are editing and eventplacement.
  public editingEventPlacement = false;
  private hasPlacements = false;

  public sectionTypeSelectionDisabled = false;
  //*endregion

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<EditVBBFormComponent>,
    private dialog: MatDialog,
    public constants: ConstService,
    private venue: VenueBuildingBlockService,
    private eventPlacementService: EventPlacementService,
    private saleCategoryService: SaleCategoryService,
    private stadiumPlanSectionService: StadiumPlanSectionService,
    private logging : LoggingService,
    private loader:HttpLoaderService,
    private vbbValidator : VBBValidator,
    private helperService : HelperService,
    private imageService: ImageService,
    public fileHelper: FileService,
    private translate: TranslateService,
    private confirmService: ConfirmService,
    private authService: AuthService
  ) { }


  onTypeChange(e) {

    if (e.Id == 1) {
      this.ctrlStadiumPlanSectionId.setValidators([Validators.required]);
    }
    else {
      this.ctrlStadiumPlanSectionId.clearValidators();
    }
  }

  ngOnInit() {  
    this.isNew = this.passedData.isNew;
    this.editingObject = this.passedData.payload;
    this.editingEventPlacement = (this.passedData.eventId !== null);
    this.hasPlacements = (this.editingObject !== null ? this.editingObject.HasPlacements : false);


    //when no pricecategory is set we need to select the inherited
    if (this.editingObject.PriceCategoryId == null) this.editingObject.PriceCategoryId = -1;
    if (this.editingObject.SaleCategoryId == null) this.editingObject.SaleCategoryId = -1;

    if (this.editingObject.HexColorCode != null) this.color = this.editingObject.HexColorCode;

    if (this.passedData.payload.VenueBuildingBlockTypeId && this.passedData.payload.VenueBuildingBlockTypeId !== null) {
      ///When a type is passed we need to force this type.
      this.editingObject.VenueBuildingBlockTypeId = this.passedData.payload.VenueBuildingBlockTypeId;
      this.sectionTypeSelectionDisabled = true;
    }

    //set up the autocomplete
    this.venueParentControl = new FormControl();

    if (this.passedData.payload.parentId && this.passedData.payload.parentId !== null) {

      //loads and sets the parent
      let parentId = this.passedData.payload.parentId;
      let observable : Observable<any> = (this.editingEventPlacement == true ? this.eventPlacementService.getEventPlacement(parentId) : this.venue.getVenueBuildingBlock(parentId));

      observable.subscribe((data:any) => {
        this.venueParentControl.setValue(data);
      });
    }

    else {
      this.venueParentControl.valueChanges.pipe(
        debounceTime(400)) //give the user some time to end the query
        .subscribe(data => {

          let additionalFilters : { [key: string]: string; } = {};
          additionalFilters['exclude-with-placements'] = '1';

          
            //max 200 results sorted on name
            this.venue.searchForAutocomplete(data, 1, 200, 'Name', false, additionalFilters).subscribe(data =>{
                this.filteredParentVenues = data.Records
            })
        });
    }

    this.stadiumPlanSectionService.getSelectablePlans().subscribe((data: any) => {
      this.stadiumPlanSections = data;
    });


    this.updateSelectablePriceCategories(this.editingObject.ParentId);
    this.updateSelectableSaleCategories(this.editingObject.ParentId);

    this.vbbValidator.editingObject = this.editingObject;
    this.ctrlStadiumPlanSectionId = new FormControl('');

    let vbbTypeIdCtrl = new FormControl(null);

    this.form = new FormGroup({
      Id: new FormControl(''), //Passed to preserve value
      Version: new FormControl(''),
      Name: new FormControl('', Validators.required),
      ShortName: new FormControl(''),
      VenueBuildingBlockTypeId: vbbTypeIdCtrl,
      StadiumPlanSectionId: this.ctrlStadiumPlanSectionId,
      ImageId: new FormControl(null),
      PriceCategoryId: new FormControl('', Validators.required),
      SaleCategoryId: new FormControl('', Validators.required),
      SectionNr: new FormControl('', [Validators.required, Validators.pattern('[0-9]+')]), //[this.vbbValidator.nrIsTaken.bind(this)]
      Parent: this.venueParentControl,
      HexColorCode: new FormControl(''),
      TicketNamingType: new FormControl(''), 
      PanoramaUrl: new FormControl(''),
      PanoramaImageId: new FormControl(null)
    });

    if(this.isInArchiveMode) 
      this.form.disable();

    if (this.sectionTypeSelectionDisabled === true) {
      vbbTypeIdCtrl.disable();
      this.venueParentControl.disable();
    }

    this.form.patchValue(this.editingObject);
    
    this.refreshImages().subscribe((result) => { this.images = result });
    this.refreshPanoramaImages().subscribe((result) => { this.panoramaImages = result });


    this.ticketNamingType.push ({TicketNamingType: null, Name: this.translate.instant('LOCATION.SECTION.TICKETNAMINGTYPE.NOCHOICE')})
    this.ticketNamingType.push ({TicketNamingType: 1, Name: this.translate.instant('LOCATION.SECTION.TICKETNAMINGTYPE.USESHORTNAME')})
    this.ticketNamingType.push ({TicketNamingType: 2, Name: this.translate.instant('LOCATION.SECTION.TICKETNAMINGTYPE.USEFULLNAME')})
  }

  selectablePriceCategories = null;

  updateSelectablePriceCategories(parentId) {
    //retrieve the selectable priceCategories
    this.venue.listSelectablePriceCategories((parentId == null ? 0 : parentId)).subscribe((data: any) => {
      this.helperService.setInheritedPriceCategoryNames(data);
      this.priceCategories = data;
      this.selectablePriceCategories = data;
    });

  }

  selectableSaleCategories = null;

  updateSelectableSaleCategories(parentId) {
    this.saleCategoryService.listSelectableSaleCategories((parentId == null ? 0 : parentId)).subscribe((data: any) => {
      this.helperService.setInheritedPriceCategoryNames(data);
      this.selectableSaleCategories = data;
      this.saleCategories = data;
    });
  }

  save(eventIds?: Array<number>) {

    const o = this.form.value;

    this.saving = true;
    this.loader.open();

    this.logging.log('Storing venue');

    // We are just interested in the ID
    if (this.form.get('VenueBuildingBlockTypeId').value !== 1) {
      o.ParentId = this.form.get("Parent").value.Id;
      o.Parent = null;
    }

    // when inherited is selected we need to send null
    if (o.PriceCategoryId === '-1' || o.PriceCategoryId === -1) o.PriceCategoryId = null;
    if (o.SaleCategoryId === '-1' || o.SaleCategoryId === -1) o.SaleCategoryId = null;
    if (o.TicketNamingType === '') o.TicketNamingType = null;
 
    if (this.color != null) o.HexColorCode = this.color;

    if (this.sectionTypeSelectionDisabled === true) {
      o.VenueBuildingBlockTypeId = this.passedData.payload.VenueBuildingBlockTypeId;
    }


    // store the object and return the id
    if (this.isNew) {

      if (o.ParentId && o.ParentId != null) {
        this.venue.getLinkedEvents(o.ParentId).subscribe((events: any) => {

          this.loader.close();

          if (events != null && events.length > 0) {

            let title = this.translate.instant('LOCATIONEDIT.CREATESECTION.CONFIRM.TITLE');
            let msg = this.translate.instant('LOCATIONEDIT.CREATESECTION.CONFIRM.MESSAGE');

            let done = 0;
            let evList = '';

            for (let e of events) {

              done ++;

              if (done > 10) {
                let moremsg = this.translate.instant('LOCATIONEDIT.CREATESECTION.CONFIRM.MOREMESSAGE');
                moremsg = moremsg.replace('[count]', events.length - 10);
                evList += `<li>${moremsg}</li>`;
                break;
              }
              else {
                var dte = moment(e.StartDateTime);
                evList += `<li>${dte.format("DD-MM-YYYY hh:mm")} - ${e.Name}</li>`;
              }
            }

            evList = "<ul>" + evList + "</ul>";

            msg = msg.replace('[eventlist]', evList);

            this.confirmService.confirm({
              title: title,
              message: msg,
              okonly: false
            },'500px').subscribe((confirmed: boolean) => {
              this.saving = false;

              if (confirmed === true) {
                this.createSection(o);
              }
            });

          }
          else {
            //nothing linked, store directly
            this.createSection(o);
          }
        });
      }
      else {
        this.createSection(o);
      }

    }
    else {

      this.logging.log('updating section/location');
      
      let storeObservable = null;

      if(this.editingEventPlacement) {
        const args: UpdateEventPlacementArgs = {
          Id: o.Id,
          Name: o.Name,
          PriceCategoryId: o.PriceCategoryId,
          Version: o.Version,
          SectionNr: o.SectionNr,
          ShortName: o.ShortName,
          SaleCategoryId: o.SaleCategoryId, 
          StadiumPlanSectionId: o.StadiumPlanSectionId,
          TicketNamingType: o.TicketNamingType, 
          PanoramaUrl: o.PanoramaUrl,
          PanoramaImageId: o.PanoramaImageId

        };
        storeObservable = this.eventPlacementService.updateEventPlacement(args);
      } else {
        storeObservable = this.venue.updateVenueBuildingBlock({ Resource: o, EventIds: eventIds });
      }

      storeObservable.subscribe((data: any) => {
        //close this dialog and pass the newly added id to the called
        this.dialogRef.close(o.Id);
        this.saving = false;
        this.loader.close();
      });
    }
  }

  createSection(o: any) {

    this.loader.open();

    //this is a new vbb
    o.Id = 0;

    if (o.StadiumPlanSectionId == '') o.StadiumPlanSectionId = null;

    this.venue.createVenueBuildingBlock(o).subscribe((id: number) => {
      //close this dialog and pass the newly added id to the called
      this.dialogRef.close(id);
      this.loader.close();
    });
  }

  submit() {

    // If editing VBB ask which events to update
    if (this.isNew === false && !this.editingEventPlacement) {
      const dialogRef: MatDialogRef<any> = this.dialog.open(VbbUpdateEpDialogComponent, {
        width: '720px',
        disableClose: true,
        data: { vbbId: this.form.get('Id').value, type: 'details' }
      });

      dialogRef.afterClosed().subscribe((data) => {
        if (data !== null)
          this.save(data);
      });
    }
    else {
      this.save();
    }
  }

  getSelectedVenueDisplayText(venue) {
    if (venue == null) return '';
    let result = `${venue.Id} - ${venue.Name}`;
    if (venue.Version != null && venue.Version != '') result += ` - ${venue.Version}`;
    return result;
  }

  getVBBName(vbb) {
    let result: string = vbb.Name;

    if (vbb.Version != null)
    {
      result += this.translate.instant('DIALOGS.EDIT-VBB.FORM.VBB-VERSION', {vbbVersion: vbb.Version});
    }

    return result;
  }

  refreshPanoramaImages() {
    return this.imageService.getAllImages(ImageTypeEnum.Panorama);
  }

  refreshImages() {
    return this.imageService.getAllImages(ImageTypeEnum.Default);
  }

  openFileInputDialog(destinationFormControlName, type: ImageTypeEnum) {
    const fileInputDialogRef: MatDialogRef<any> = this.dialog.open(FileInputDialogComponent, {
      disableClose: false,
      data: {
        title: this.translate.instant('LOCATION.LOCATIONS.EDITDIALOG.FORM.FILEINPUTDIALOG.TITLE'), 
        description: this.translate.instant('LOCATION.LOCATIONS.EDITDIALOG.FORM.FILEINPUTDIALOG.DESCRIPTION'),
        imageType: type
      }
    });
    fileInputDialogRef.afterClosed()
      .subscribe(data => {
          if (data) {
            var newId = +data;
            if (type == ImageTypeEnum.Panorama) {
              this.refreshPanoramaImages().subscribe((result) => {
                this.panoramaImages = result;
                this.form.controls[destinationFormControlName].setValue(newId);
              });
            }
            else {
              this.refreshImages().subscribe((result) => {
                this.images = result;
                this.form.controls[destinationFormControlName].setValue(newId);
              });
            }
          }
        }
      );
  }

  openVenueSvgInputDialog(destinationFormControlName) {
    const fileInputDialogRef: MatDialogRef<any> = this.dialog.open(VenueSvgInputDialogComponent, {
      disableClose: false,
      data: {
        title: 'STADIUMPLANSECTION.SVG.FILE.UPLOAD.TITLE',
        description: 'STADIUMPLANSECTION.SVG.FILE.UPLOAD.DESCRIPTION'
      }
    });
    fileInputDialogRef.afterClosed()
      .subscribe(data => {
          if (data) {
            const newId = +data;
            this.stadiumPlanSectionService.getSelectablePlans().subscribe((innerData: any) => {
              this.stadiumPlanSections = innerData;

              this.form.controls[destinationFormControlName].setValue(newId);
            });
          }
        }
      );
  }
}
