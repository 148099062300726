import {Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AssignmentPolicyModel } from '../../api';

@Component({
  selector: 't4-timespan-control',
  templateUrl: 'timespan-control.component.html', 
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi:true,
    useExisting: TimeSpanControlComponent
  }]
})
export class TimeSpanControlComponent implements ControlValueAccessor {
  
  public timeSpanText:string = '';
  public timeSpanTextDays:string = '';
  public timeSpanTextHours:string = '';
  public timeSpanTextMinutes:string = '';
  public policy: AssignmentPolicyModel;

  onChange = (dte: string) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;

  @Input() required: boolean = true;
  @Input() labelTimeSpan: string = ''; 
  @Input() timeSpanPlaceholder: string = 'ddd.hh:mm:ss';
  @Input() colWidth: number = 6;
  @Input() RevokeTimeSpan: string;

  @Output() changeEvent = new EventEmitter<string>();

  public formControl: FormControl = new FormControl();

  constructor() {
  }

  parseFormattedTime(input: string) {
    const regex = /(\d+)\.(\d{2}):(\d{2}):(\d{2})/;
    const match = input.match(regex);

    if (!match) {
        throw new Error("Invalid format. Expected format: <days>.hh:mm:ss");
    }

    const [, days, hours, minutes, seconds] = match.map(String);

    this.timeSpanTextDays = days;
    this.timeSpanTextHours = hours;
    this.timeSpanTextMinutes= minutes;

}

ngOnInit() {
  this.parseFormattedTime(this.RevokeTimeSpan);
}

  writeValue(dte: string) {
    this.timeSpanText = dte;
    this.formatText();
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  formatText() {
    
    if(parseInt(this.timeSpanTextHours) >= 24) {
      this.timeSpanTextHours = "23"
    }
    if(parseInt(this.timeSpanTextMinutes) >= 60) {
      this.timeSpanTextMinutes = "59"
    }

    const days = this.timeSpanTextDays || '0';
    let hours = this.timeSpanTextHours?.padStart(2, '0') || '00';
    let minutes = this.timeSpanTextMinutes?.padStart(2, '0') || '00';
    this.timeSpanText = days + '.' + hours + ':' + minutes + ':' + '00'

  }

  capValues() {

  }

  onTimeSpanChange(e: string) {
    this.formatText();
    this.markAsTouched();
    this.onChange(this.timeSpanText);
    this.changeEvent.emit(this.timeSpanText);
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}