/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MailTemplate { 
    Id?: number;
    Created?: Date | null;
    Modified?: Date | null;
    Deleted?: Date | null;
    TypeCode?: string | null;
    Body?: string | null;
    Subject?: string | null;
    From?: string | null;
    FromFriendly?: string | null;
    LanguageId?: number | null;
    CustomerId?: number | null;
    EventTypeId?: number | null;
    SystemManaged?: boolean;
    Disabled?: boolean;
    SendOnServiceBus?: boolean;
}

