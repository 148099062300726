<div class="row modal-bar">

    <div class="col-sm-6">
        <h5 class="modal-title">{{title | translate}}</h5>
    </div>

    <div class="col-sm-6">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
</div>
<div class="d-flex flex-row row">
    <div class="d-flex flex-row w-100 p-15">
        <div class="d-flex flex-column flex-fill w-50">
            <div class="d-flex info-highlight mt-3">
                <div class="flex-row w-100">
                    <div class="row w-100 mt-0 h-unset">
                        <div class="col-12 w-100 d-inline-flex align-items-center">
                            <div class="d-flex align-items-center">
                                <span class="pl-12">
                                    {{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.SITUATION-BEFORE' | translate | uppercase}} 
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row w-100 mt-0">
                        <div class="col-9 w-100 d-inline-flex align-items-center">
                            <div class="d-flex align-items-center">
                                <i class="fas fa-user"></i>
                                <span class="pl-12">
                                    <p class="info-label">{{'T4.GENERIC.INTHENAMEOF' | translate | uppercase}}</p>
                                    <p class="info-data">
                                        <span
                                            [ngClass]="{'text-danger': orderLine.TicketCustomerIsBlacklisted, 'font-weight-bold': orderLine.TicketCustomerIsBlacklisted}">
                                            <t4-customer-identification-control [isIbaActive]="isIbaActive" [showCustomerNumber]="true" [customer]="constructCustomerIdentificationModel(orderLine)"></t4-customer-identification-control>
                                            <span *ngIf="orderLine.TicketCustomerIsBlacklisted">{{ 'SALES-ENTITY-MODIFICATION.LISTVIEW.CHECK.BLACKLISTED' | translate }} </span>
                                        </span>
                                    </p>
                                </span>
                            </div>
                        </div>
                        <div class="col-3 w-100 d-inline-flex align-items-center mt-0">
                            <button class="btn btn-primary w-100 mt-0 d-flex align-items-center" (click)="openDialog('customer')" [disabled]="disabledItems.includes('user')">
                                <i class="fas fa-exchange-alt col-3"></i>
                                <p class="info-label d-inline text-center col-9">{{'CUSTOM.BUTTON.STATE.LIST.EDIT' | translate }}</p>
                            </button>
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-9 w-100 d-inline-flex align-items-center">
                            <div class="d-flex align-items-center">
                                <i class="fas fa-chair"></i>
                                <span class="pl-12">
                                    <p class="info-label">{{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.POSITION' | translate | uppercase}}</p>
                                    <p class="info-data">
                                        <span>
                                            {{orderLine.ParentSectionName}} / {{orderLine.RowNumber | replaceOnEmpty}} / {{orderLine.SeatNumber | replaceOnEmpty}}
                                        </span>
                                    </p>
                                </span>
                            </div>
                        </div>
                        <div class="col-3 w-100 d-inline-flex align-items-center">
                            <button class="btn btn-primary w-100 mt-0 d-flex align-items-center" (click)="openDialog('position')" [disabled]="disabledItems.includes('position')">
                                <i class="fas fa-exchange-alt col-3"></i>
                                <p class="info-label d-inline text-center col-9">{{'CUSTOM.BUTTON.STATE.LIST.EDIT' | translate }}</p>
                            </button>
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-9 w-100 d-inline-flex align-items-center">
                            <div class="d-flex align-items-center">
                                <i class="fas fa-door-closed"></i>
                                <span class="pl-12">
                                    <p class="info-label">{{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.ENTRANCE' | translate | uppercase}}</p>
                                    <p class="info-data">
                                        <span>
                                            {{orderLine.SelectedSingleEntranceName | replaceOnEmpty: 'SALES-ENTITY-MODIFICATION.LISTVIEW.STANDARD.DEFAULT-ON-EMPTY' | translate}}
                                        </span>
                                    </p>
                                </span>
                            </div>
                        </div>
                        <div class="col-3 w-100 d-inline-flex align-items-center">
                            <button class="btn btn-primary w-100 mt-0 d-flex align-items-center" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [disabled]="disabledItems.includes('entrance')">
                                <i class="fas fa-angle-down col-3"></i>
                                <p class="info-label d-inline text-center col-9">{{ 'SALES-ENTITY-MODIFICATION.LISTVIEW.PLACEHOLDER.ENTRANCE' | translate }}</p>
                            </button>

                            <div class="dropdown-menu p-0" aria-labelledby="dropdownMenuButton" x-placement="bottom-start"
                            style="position: absolute; transform: translate 3d(0px, 41px, 0px); top: 0px; left: 0px; will-change: transform;">
                                <ng-select #entranceSelect [isOpen]="true" [items]="selectableEntrances" aria-labelledby="dropdownMenuButton" bindLabel="EntranceName" bindValue="EntranceId" placeholder="{{ 'SALES-ENTITY-MODIFICATION.LISTVIEW.PLACEHOLDER.ENTRANCE' | translate }}"
                                [clearable]="false" notFoundText="{{ 'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" (change)="onSelectedSingleEntranceChange($event)"></ng-select>
                            </div>
                        </div>
                    </div>
                    <hr class="row w-100">
                    <div class="row w-100">
                        <div class="col-9 w-100 d-inline-flex align-items-center">
                            <div class="d-flex align-items-center">
                                <i class="fas fa-credit-card"></i>
                                <span class="pl-12">
                                    <p class="info-data">{{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.PRICE' | translate | uppercase}}</p>
                                </span>
                            </div>
                        </div>
                        <div class="col-3 w-100 d-inline-flex align-items-center">
                            <button class="btn btn-primary w-100 mt-0 d-flex align-items-center" (click)="openDialog('price')">
                                <i class="fas fa-exchange-alt col-3"></i>
                                <p class="info-label d-inline text-center col-9">{{'CUSTOM.BUTTON.STATE.LIST.EDIT' | translate }}</p>
                            </button>
                        </div>
                    </div>
                    <hr class="row w-100">
                    <div class="row w-100">
                        <div class="col-9 w-100 d-inline-flex align-items-center">
                            <div class="d-flex align-items-center">
                                <i class="fas fa-barcode"></i>
                                <span class="pl-12">
                                    <p class="info-data">{{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.NEW-BARCODE' | translate | uppercase}}</p>
                                </span>
                            </div>
                        </div>
                        <div class="col-3 w-100 d-inline-flex align-items-center">
                            <button class="btn w-100 mt-0" *ngIf="!disabledItems.includes('barcode')">
                                <input
                                    type="checkbox" appToggle
                                    [onText]="translate.instant('GENERIC.BOOLEANTYPE.YES')" [offText]="translate.instant('GENERIC.BOOLEANTYPE.NO')"
                                    [(ngModel)]="this.changes.NewBarcode"
                                >
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column arrow p-0">
            <div class="d-flex mt-3 pt-16 arrow-buffer">
                <div class="flex-row w-100">
                    <div class="row w-100 mt-0">
                        <div class="w-100 d-flex"></div>
                    </div>
                </div>
            </div>
            <div class="d-flex row w-100 mt-0">
                <div class="flex-row w-100">
                    <div class="row w-100 mt-0">
                        <div class="w-100 d-flex align-items-center justify-content-center" *ngIf="(changes.CustomerId || newCustomer)">
                            <i class="fas fa-arrow-alt-circle-right"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex row w-100 m-0">
                <div class="flex-row w-100">
                    <div class="row w-100 mt-0">
                        <div class="w-100 d-flex align-items-center justify-content-center" *ngIf="(changes.EventPlacementId || changes.CustomerPassePartoutId || newPosition)">
                            <i class="fas fa-arrow-alt-circle-right"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex row w-100">
                <div class="flex-row w-100">
                    <div class="row w-100 mt-0">
                        <div class="w-100 d-flex align-items-center justify-content-center" *ngIf="(changes.EntranceId || selectedEntranceName)">
                            <i class="fas fa-arrow-alt-circle-right"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex row w-100">
                <div class="flex-row w-100">
                    <div class="row w-100 mt-0">
                        <div class="w-100 d-flex align-items-center justify-content-center" *ngIf="(changes.ManualPrice || changes.AppliedCredit || changes.PriceVariationId)">
                            <i class="fas fa-arrow-alt-circle-right"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex row w-100">
                <div class="flex-row w-100">
                    <div class="row w-100 mt-0">
                        <div class="w-100 d-flex align-items-center justify-content-center" *ngIf="(changes.NewBarcode)">
                            <i class="fas fa-arrow-alt-circle-right"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column flex-fill w-50">
            <div class="d-flex info-highlight mt-3">
                <div class="flex-row w-100">
                    <div class="row w-100 mt-0 h-unset">
                        <div class="col-12 w-100 d-inline-flex align-items-center">
                            <div class="d-flex align-items-center">
                                <span class="pl-12">
                                    {{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.SITUATION-AFTER' | translate | uppercase}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row w-100 mt-0">
                        <div class="col-10 w-100 d-inline-flex align-items-center">
                            <div class="d-flex align-items-center">
                                <i class="fas fa-user"></i>
                                <span class="pl-12">
                                    <p class="info-label">{{'T4.GENERIC.INTHENAMEOF' | translate | uppercase}}</p>
                                    <p class="info-data">
                                        <span
                                            [ngClass]="{'text-danger': orderLine.TicketCustomerIsBlacklisted, 'font-weight-bold': orderLine.TicketCustomerIsBlacklisted}">
                                            <t4-customer-identification-control *ngIf="newCustomer" [isIbaActive]="isIbaActive" [showCustomerNumber]="true" [customer]="constructCustomerIdentificationModel(newCustomer)"></t4-customer-identification-control>
                                            <span *ngIf="!newCustomer">{{newCustomer | replaceOnEmpty:'SALES-ENTITY-MODIFICATION.LISTVIEW.CHECK.UNEDITED'}}</span>
                                            <span *ngIf="orderLine.TicketCustomerIsBlacklisted">{{ 'SALES-ENTITY-MODIFICATION.LISTVIEW.CHECK.BLACKLISTED' | translate }} </span>
                                        </span>
                                    </p>
                                </span>
                            </div>
                        </div>
                        <div class="col-2 w-100 d-flex align-items-center justify-content-end" *ngIf="(changes.CustomerId || newCustomer) && !disabledItems.includes('user')">
                            <button class="btn btn-danger rounded-circle" (click)="cancel('user')">
                                <i class="fas fa-undo"></i>
                            </button>
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-10 w-100 d-inline-flex align-items-center">
                            <div class="d-flex align-items-center">
                                <i class="fas fa-chair"></i>
                                <span class="pl-12">
                                    <p class="info-label">{{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.POSITION' | translate | uppercase}}</p>
                                    <p class="info-data">
                                        <span>
                                            {{newPosition | replaceOnEmpty:'SALES-ENTITY-MODIFICATION.LISTVIEW.CHECK.UNEDITED'}}
                                        </span>
                                    </p>
                                </span>
                            </div>
                        </div>
                        <div class="col-2 w-100 d-flex align-items-center justify-content-end" *ngIf="(changes.EventPlacementId || newPosition) && !disabledItems.includes('position')">
                            <button class="btn btn-danger rounded-circle" (click)="cancel('position')">
                                <i class="fas fa-undo"></i>
                            </button>
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-10 w-100 d-inline-flex align-items-center">
                            <div class="d-flex align-items-center">
                                <i class="fas fa-door-closed"></i>
                                <span class="pl-12">
                                    <p class="info-label">{{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.ENTRANCE' | translate | uppercase}}</p>
                                    <p class="info-data">
                                        <span>
                                            {{selectedEntranceName | replaceOnEmpty:'SALES-ENTITY-MODIFICATION.LISTVIEW.CHECK.UNEDITED' | translate}}
                                        </span>
                                    </p>
                                </span>
                            </div>
                        </div>
                        <div class="col-2 w-100 d-flex align-items-center justify-content-end" *ngIf="(changes.EntranceId || selectedEntranceName) && !disabledItems.includes('entrance')">
                            <button class="btn btn-danger rounded-circle" (click)="cancel('entrance')">
                                <i class="fas fa-undo"></i>
                            </button>
                        </div>
                    </div>
                    <hr class="row w-100">
                    <div class="row w-100">
                        <div class="col-10 w-100 d-inline-flex align-items-center">
                            <div class="d-flex align-items-center w-100">
                                <i class="fas fa-credit-card"></i>
                                <span class="pl-12 w-100">
                                    <p class="info-label">
                                        <span>{{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.PRICE-MUTATIONS' | translate | uppercase}}</span>
                                    </p>
                                    <p class="d-inline-flex w-100" *ngIf="newTotal">
                                        {{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.ORIGNAL-PRICE' | translate | uppercase}} {{orderLine.Total | currency:'EUR' | replaceOnEmpty}}
                                        <span class="flex-grow-1 text-center">></span>
                                        <span class="font-weight-bold">{{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.NEW-PRICE' | translate | uppercase}} {{newTotal | currency:'EUR' | replaceOnEmpty}}</span>
                                    </p>
                                    <p class="info-data" *ngIf="!newTotal">
                                        {{'SALES-ENTITY-MODIFICATION.LISTVIEW.CHECK.UNEDITED' | translate}}
                                    </p>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="priceIsChanged()" class="col-2 w-100 d-flex align-items-center justify-content-end">
                            <button #priceToggle class="btn btn-warning rounded-circle price-toggle" (click)="togglePriceHover();">
                                <i class="fas" [ngClass]="showPriceHover ? 'fa-angle-up' : 'fa-angle-down'"></i>
                            </button>
                            <button class="btn btn-danger rounded-circle" (click)="cancel('price');">
                                <i class="fas fa-undo"></i>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="showPriceHover" #priceFoldOut class="justify-content-end w-100 d-flex">
                        <div class="card card-body price-hover row flex-row position-absolute">
                            <div class="col-6"*ngIf="orderLine.ProductType == 1 || orderLine.ProductType == 4 || orderLine.ProductType == 7">
                                {{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.PRICE.CATEGORY' | translate | uppercase}}
                            </div>
                            <div class="col-6 justify-content-end text-right font-weight-bold" *ngIf="orderLine.ProductType == 1 || orderLine.ProductType == 4 || orderLine.ProductType == 7">
                                {{newPriceData.selectedVariant.VariationName | replaceOnEmpty:'SALES-ENTITY-MODIFICATION.LISTVIEW.CHECK.UNEDITED' | translate | uppercase}}
                            </div>
                            <div class="col-6">
                                {{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.PRICE.NEW-PRICE' | translate | uppercase}}
                            </div>
                            <div class="col-6 justify-content-end text-right font-weight-bold">
                                {{newPriceData.manualPrice | currency:'EUR' | replaceOnEmpty}} <i>[{{'ADMINISTRATION.SYSTEM-RESOURCES.MANUALLY' | translate | uppercase}}]</i>
                            </div>
                            <div class="col-6">
                                {{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.PRICE.BALANCE' | translate | uppercase}}
                            </div>
                            <div class="col-6 justify-content-end text-right font-weight-bold">
                                {{calculateLineBalance() | currency:'EUR' | replaceOnEmpty}}
                            </div>
                        </div>
                    </div>
                    <hr class="row w-100">
                    <div class="row w-100">
                        <div class="col-10 w-100 d-inline-flex align-items-center">
                            <div class="d-flex align-items-center">
                                <i class="fas fa-barcode"></i>
                                <span class="pl-12">
                                    <p class="info-label">{{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.GENERATE-NEW-BARCODE' | translate | uppercase}}</p>
                                </span>
                            </div>
                        </div>
                        <div class="col-2 w-100 d-inline-flex align-items-center justify-content-end">
                            <span class="info-label">
                                {{changes.NewBarcode ? translate.instant('GENERIC.BOOLEANTYPE.YES') : translate.instant('GENERIC.BOOLEANTYPE.NO')}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="d-flex flex-row w-100 p-15">
        <div class="d-flex flex-column flex-fill w-50">
            <div class="info-highlight mt-3">
                <div class="w-100">
                    <div class="w-100 mt-0">
                        <p class="info-data title">{{'SALES-ENTITY-MODIFICATION.LISTVIEW.HEADER.COMMENT' | translate | uppercase}}</p>
                        <textarea [(ngModel)]="changes.Comment" maxlength="255" class="w-100 comment-area" placeholder="{{ 'SALES-ENTITY-MODIFICATION.LISTVIEW.PLACEHOLDER.COMMENT' | translate }}"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column arrow p-0">
            <div class="d-flex mt-3 p-16">
                <div class="flex-row w-100">
                    <div class="row w-100 mt-0">
                        <div class="w-100 d-flex"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column flex-fill mt-3 w-50">
            <div class="info-highlight flex-row flex-wrap h-100">
                <p class="info-data mt-3 title">{{'SALES.ORDERS.OVERVIEW.LISTVIEW.HEADER.SUMMARY' | translate | uppercase}}</p>
                <div class="w-100 mt-0 d-inline-flex align-items-center">
                    <i class="fas fa-edit info-label"></i>
                    <span class="">
                        <p class="info-label" *ngIf="getNumberOfChanges() > 0">{{'SALES.ORDERS.OVERVIEW.LISTVIEW.HEADER.CHANGES' | translate : {noOfChanges : getNumberOfChanges()} }}</p>
                        <p class="info-label" *ngIf="getNumberOfChanges() == 0">{{'SALES.ORDERS.OVERVIEW.LISTVIEW.HEADER.NOCHANGES' | translate}}</p>
                    </span>
                </div>
                <div class="w-100 d-inline-flex align-items-center" *ngIf="changes.NewBarcode">
                    <i class="fas fa-barcode info-label"></i>
                    <span class="">
                        <p class="info-label">{{'SALES.ORDERS.OVERVIEW.LISTVIEW.HEADER.NEW-BARCODE' | translate}}</p>
                    </span>
                </div>
                <div class="w-100 d-inline-flex align-items-center">
                    <i class="fas fa-user info-label"></i>
                    <span class="">
                        <p class="info-label">{{'SALES.ORDERS.OVERVIEW.LISTVIEW.HEADER.CHANGES-MADE-BY' | translate : {user : loggedInUser.Fullname} }}</p>
                    </span>
                </div>
                <div class="w-100 d-inline-flex align-items-center">
                    <i class="fas fa-calendar info-label"></i>
                    <span class="">
                        <p class="info-label">{{'SALES.ORDERS.OVERVIEW.LISTVIEW.HEADER.CHANGES-MADE-ON' | translate}} {{date}} | {{localTime | date: 'humm'}}</p>
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="text-center submit-buttons w-100">
        <button class="btn btn-primary" (click)="submit()" [disabled]="!hasChanges()">
            <i class="fas fa-save"></i>
            {{'GENERIC.BUTTON.SAVE' | translate | uppercase}}
        </button>
        <button class="btn btn-dark" (click)="cancel('position'); dialogRef.close()">
            <i class="fas fa-times"></i>
            {{'GENERIC.BUTTON.CANCEL' | translate | uppercase}}
        </button>
    </div>
</div>
