    <div class="form-group">
      <div class="row">
        <div class="col-4">
          <span>
            {{ 'DIALOGS.EDIT-MEMBERSHIP.FORM.DROPDOWN.DAYS' | translate }}:
          </span>
          <input [disabled]="disabled" id="input-days" class="form-control" [value]="timeSpanTextDays" [(ngModel)]="timeSpanTextDays" type="text" (change)="onTimeSpanChange($event)"/>
        </div>
        <div class="col-4">
          {{ 'GENERIC.HOURS' | translate }}:
          <input [disabled]="disabled" id="input-hours" class="form-control" [value]="timeSpanTextHours" [(ngModel)]="timeSpanTextHours" type="text" (change)="onTimeSpanChange($event)"/>
        </div>
        <div class="col-4">
          {{ 'GENERIC.MINUTES' | translate}}:
          <input [disabled]="disabled" id="input-minutes" class="form-control" [value]="timeSpanTextMinutes" [(ngModel)]="timeSpanTextMinutes" type="text" (change)="onTimeSpanChange($event)"/>
        </div>
      </div>
    </div>