import {Injectable} from '@angular/core';
import {SnackbarService} from '@tymes4-shared';

@Injectable()
export class SnackbarHelper {

  constructor(private snackBar: SnackbarService) {
  }

  open(msg: string) {
    this.snackBar.open(msg, 'GENERIC.SNACKBAR.CLOSE');
  }

  saved() {
    this.open('GENERIC.SNACKBAR.SAVED');
  }

  deleted() {
    this.open('GENERIC.SNACKBAR.DELETED');
  }

  credited() {
    this.open('GENERIC.SNACKBAR.CREDITED');
  }

  imported() {
    this.open('GENERIC.SNACKBAR.IMPORTED');
  }

  saveFailed() {
    this.open('GENERIC.SNACKBAR.SAVE-FAILED');
  }

  deleteFailed() {
    this.open('GENERIC.SNACKBAR.DELETE-FAILED');
  }

  importFailed() {
    this.open('GENERIC.SNACKBAR.IMPORT-FAILED');
  }


}
