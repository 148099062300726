/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EventPolicyEnum } from './eventPolicyEnum';


export interface EventTicketshopDetails { 
    VisibleInShop?: boolean;
    VisibleInShopFrom?: Date | null;
    VisibleInShopTill?: Date | null;
    HighlightInShop?: boolean;
    DisplayCountdown?: boolean;
    PolicyId?: EventPolicyEnum;
    HasSoldOut?: boolean;
    SingleSeatCheckActive?: boolean;
    DisplayPanorama?: boolean;
}

