import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Router } from '@angular/router';
import { ConfirmService, HttpLoaderService, LoggingService, ReplaceOnEmptyPipe, SnackbarService, PrintOrDownloadTicketsComponent, localDateTimePipe, NotificationCommunicationService } from '@tymes4-shared';
import { DialogHelper, DialogWidth, PageModule } from '@tymes4-shared';
import * as FileSaver from 'file-saver';
import { EditPaymentMethodComponent } from '../edit-payment-method/edit-payment-method.component';
import {
  ManualMailDialogComponent,
  ManualMailDialogParameters,
  ManualMailDialogResult
} from '../manual-mail-dialog/manual-mail-dialog.component';
import { PrintOrderHardcardsComponent } from '../print-order-hardcards/print-order-hardcards.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LongRunningOperationService, MailTemplateService, OrderService, SendMailETicketArgs, TicketDownloadArgs, TicketPrintArgs, TicketService } from '../../api';
import { AuthService } from '../../services/auth.service';
import { ApplicableRefactorInfo } from 'typescript';
import { ApplicationModuleHelperService } from '../../services/application-module.service';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-secondary-order-details',
  templateUrl: './secondary-order-details.component.html',

  providers: [ReplaceOnEmptyPipe]
})
export class SecondaryOrderDetailsComponent implements OnInit {

  static numberOfButtons = 0;
  static buttons = {
    //todo add cancel
  };

  public form: FormGroup;
  public orderDetails: MarketplaceOrderDetails = null;
  // private billingCustomer = null;
  private editingObject = null;
  public saving = false;
  showPaymentDetails = true;

  selectedObjects = [];


  pagedHistoryLines = [];
  lastUsedPageHistory = null;
  orderPaymentHistory = null;

  public isReadOnly = false;
  public showButton = [];
  public isInArchiveMode = this.authService.isInArchiveMode();
  public isIbaActive: boolean = false;

  @ViewChild('paymentOverview') paymentOverview;

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              public dialogRef: MatDialogRef<SecondaryOrderDetailsComponent>,
              private dialog: MatDialog,
              private apploader: HttpLoaderService,
              private logging: LoggingService,
              private confirmService: ConfirmService,
              private ticketService: TicketService,
              private router: Router,
              private snackBar: SnackbarService,
              private orderService: OrderService,
              private mailTemplateService: MailTemplateService,
              private datePipe: DatePipe,
              private localDateTimePipe: localDateTimePipe,
              private replaceOnEmpty: ReplaceOnEmptyPipe,
              private translate: TranslateService,
              private authService: AuthService,
              private applicationModuleHelperService: ApplicationModuleHelperService,
              private notificationCommunicationService : NotificationCommunicationService,
              private lroService: LongRunningOperationService) {
  }

  ngOnInit() {
    this.orderDetails = this.passedData.order;

    if (this.passedData.isReadOnly)
      this.isReadOnly = true;

    // for (const key in OrderDetailsComponent.buttons) {
    //   this.showButton[OrderDetailsComponent.buttons[key]] = true;
    // }

    if (this.passedData.buttons) {
      for (const button in this.passedData.buttons) {
        this.showButton[button] = this.passedData.buttons[button];
      }
    }

    this.applicationModuleHelperService.getActiveModules().subscribe((activeModules) => {
      if (activeModules !== null) {
        this.isIbaActive = activeModules.some((module) => module.toUpperCase() == 'IBA')
      }
    })
  }

  onTabChange(e) {

    if (e.selectedTab.id === 'payment-history') {
      this.paymentOverview.loadPaymentHistoryForOrder(this.orderDetails.Id);
    }

  }



  createInvoice() {


  }



  toggleSelection(orderLine) {

    if (orderLine == null) return;

    if (this.isItemSelected(orderLine)) {
      //remove from the selection
      const index = this.selectedObjects.indexOf(orderLine);
      this.selectedObjects.splice(index, 1);
    } else {
      this.selectedObjects.push(orderLine);
    }
  }

  isItemSelected(event) {

    if (!event) return null;

    if (this.selectedObjects == null && this.selectedObjects.length == 0)
      return false;

    const found = this.selectedObjects.filter(function (ep) {
      if (ep.Id == event.Id) return ep;
    });

    return (found != null && found.length > 0);
  }

  clearSelection() {
    this.selectedObjects = [];
  }


  calculateTotal(property) {
    let result = 0;

    for (const ol of this.orderDetails.SecondaryOrderLines) {
      result += ol[property];
    }

    return result;
  }


 // TODO check
  displayPrintDialog() {

    this.apploader.open();

    this.orderService.getSecondaryOrderPrintDetails(this.orderDetails.Id).subscribe((data:any) => {

      this.apploader.close();

      const dialogOptions = DialogHelper.GetDialogOptions(DialogWidth.lg, {
        title: this.translate.instant('SALES.ORDERS.DETAILS.DIALOG.PRINT-TICKETS.TITLE'),
        payload: data, mode: 'print', isIbaActive: this.isIbaActive
      });

      const dialogRef: MatDialogRef<any> = this.dialog.open(PrintOrDownloadTicketsComponent, dialogOptions);


      dialogRef.afterClosed()
        .subscribe(submitForm => {

            if (submitForm === null) {
              //nothing to do. The download is closed without pressing download/print
            } else {

              this.apploader.open();

              var args : TicketPrintArgs = {
                SelectedEventPlacementIds: submitForm.SelectedEventplacementIds,
                PackagingSlipOption: submitForm.packagingSlip,
                CutMode: submitForm.cutMode,
                isDuplicate: false,
                DuplicationReasonId: submitForm.DuplicationReasonId,
                SecondaryOrderId: this.orderDetails.Id
              }

              //to the download
              this.ticketService.printTicket(args).subscribe((data: any) => {
                this.apploader.close();
                this.snackBar.open(this.translate.instant('GENERIC.SNACKBAR.PRINTJOB-CREATED'), 'GENERIC.SNACKBAR.CLOSE');
              }, error => {

                this.apploader.close();

                this.confirmService.confirm({
                  title: this.translate.instant('GENERIC.SNACKBAR.SOMETHING-WENT-WRONG'),
                  message: this.translate.instant('SALES.ORDERS.DETAILS.CONFIRM.PRINT-TICKETS.FAILED.MSG'),
                  okonly: true
                }).subscribe((result) => {
                  this.dialogRef.close(false);
                });
              });
            }
          }
        );
    });

    return false;
  }

  displayDownloadDialog() {
    this.apploader.open();

    this.orderService.getSecondaryOrderPrintDetails(this.orderDetails.Id).subscribe((data:any) => {

      this.apploader.close();

      const dialogOptions = DialogHelper.GetDialogOptions(DialogWidth.lg, {
        title: this.translate.instant('SALES.ORDERS.DETAILS.CONFIRM.DOWNLOAD-ETICKETS.TITLE'),
        payload: data, mode: 'download', allowSalesChannelSelect: true, isIbaActive: this.isIbaActive
      });

      const dialogRef: MatDialogRef<any> = this.dialog.open(PrintOrDownloadTicketsComponent, dialogOptions);

      dialogRef.afterClosed()
        .subscribe(submitForm => {
              if (submitForm === null) {
                //nothing to do. The download is closed without pressing download
              } else {

                this.apploader.open();

                var args: TicketDownloadArgs ={
                  SecondaryOrderId: this.orderDetails.Id,
                  SelectedEventPlacementIds: submitForm.SelectedEventplacementIds,
                  SalesChannelId: submitForm.SalesChannelId,
                  isDuplicate: false,
                  DuplicationReasonId: submitForm.duplicationReasonId
                }

                if (submitForm.DownloadInBackground) {
                  this.lroService.registerTicketDownload(args)
                  .pipe(first())
                  .subscribe(status => {
                    this.notificationCommunicationService.updateNotificationsAndLROS();
                    this.apploader.close();
                    this.snackBar.open('GENERIC.SNACKBAR.BACKGROUND_JOB_CREATED', 'GENERIC.SNACKBAR.CLOSE');
                  });
                }
                else {
                  //to the download
                  this.ticketService.downloadTicket(args).subscribe((data: any) => {
                    this.apploader.close();

                    FileSaver.saveAs(data, `E-tickets_order_${this.orderDetails.Id}.zip`);
                  });
                }
            }
          }
        );
    });

    return false;

  }

  displayEmailDialog() {
    let data = <ManualMailDialogParameters>{};
    data.title = 'ORDER.DETAILS.TICKETS.SEND-TICKETS.TITLE';
    data.email = this.orderDetails.CustomerEmail;
    data.showSalesChannelSelection = true;

    this.orderService.getSecondaryOrderPrintDetails(this.orderDetails.Id).subscribe(printDetails => {
      data.attachments = printDetails.PrintableTickets;
      data.salesChannels = printDetails.ETicketPrintableChannels;

      this.mailTemplateService.getSecondaryConfirmationMail(this.orderDetails.Id).subscribe((result) => {
        data.body = result.Body;
        data.subject = result.Subject;

        const dialogRef: MatDialogRef<any> = this.dialog.open(ManualMailDialogComponent, {
          width: '720px',
          disableClose: true,
          data: data
        });

        dialogRef.afterClosed()
          .subscribe((result: ManualMailDialogResult) => {
              if (result) {
                this.apploader.open();

                var args : SendMailETicketArgs =
                {
                  Email: result.email,
                  Subject: result.subject,
                  Body: result.body,
                  EventPlacementIds: result.attachments,
                  SalesChannelId: result.salesChannelId
                }

                this.orderService.sendSecondaryETicketMail(this.orderDetails.Id, args).subscribe((a) => {
                  this.apploader.close();
                  this.snackBar.open(this.translate.instant('GENERIC.SNACKBAR.EMAIL-HAS-BEEN-SENT'), 'GENERIC.SNACKBAR.CLOSE');

                });
              }
            }
          );
      });
    });

    return false;

  }
}

export interface MarketplaceOrderDetails {
  AllowEticketPrinting: boolean;
  AllowEticketMailing: boolean;
  AllowHardcardPrinting: boolean;
  AllowSoftcard: boolean;
  AmountPayed: number;
  Balance: number;
  PendingPaymentBalance: number;
  Payed: true;
  Id: number;
  PrimaryContactEmail: string;
  UsedPaymentMethods: string;
  SecondaryOrderLines: Array<any>;
  OrderDate: string;
  Reference: string;
  TotalInclVat: number;
  SubscriptionViews: Array<any>;
  HasProlongationLines: boolean;
  PayPerMailAllowed: boolean;
  Locked: boolean;
  DeliveryMethodId: number;
  DeliveryMethodCode: string;
  DeliveryMethodName: string;
  PayByInvoice: boolean;
  SalesChannelName: string;
  SalesChannelId: number;
  CustomerId: number;
  CustomerName: string;
  CustomerEmail: string;
  OrderAddressLine1: string;
  OrderAddressPostalCode: string;
  OrderAddressCity: string;
  PendingCancelByCustomer: boolean;
  CanProlongOrder: boolean;
  Deleted: string;
  DiscountDisplayName: string;
  DiscountCodeId: number;
  ProductsSubTotalAmount: number;
  PaymentMethodSubTotalAmount: number;
  DeliveryMethodSubTotalAmount: number;
  DiscountSubTotalAmount: number;
  DiscountTotals: Array<any>;
  IsInvoiced: boolean;
}
