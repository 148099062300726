<div>
  <t4-list-view #listVw [options]="listOptions" (onDataRequest)="onDataRequest($event)">

    <ng-container *t4ListActions>
      <button *ngIf="!isInArchiveMode" (click)="addNew()" class="btn btn-primary border" type="button"><i class="far fa-plus-square"></i>{{ 'GENERIC.BUTTON.ADDNEW' | translate}}</button>
    </ng-container>

    <ng-container *t4ListItem="let item">
      <td><i *ngIf="item.IconCode !== null" style="color: #8991a6" class="{{ item.IconCode }}"></i></td>
      <td>{{item.ShopLabelTypeTranslationCode | translate}}</td>
      <td>{{item.Name}}</td>
      <td>{{item.DisplayText | translate | replaceOnEmpty }}</td>
      <td>{{ (item.Show ? 'GENERIC.BOOLEANTYPE.YES' : 'GENERIC.BOOLEANTYPE.NO') | translate }}</td>
      <td>
        <ng-container *ngIf="item.Show; else nofrom">{{item.ShowFrom | localDateTime | replaceOnEmpty }}</ng-container>
        <ng-template #nofrom>-</ng-template>
      </td>
      <td>
        <ng-container *ngIf="item.Show; else noto">{{item.ShowTill | localDateTime | replaceOnEmpty }}</ng-container>
        <ng-template #noto>-</ng-template>
      </td>
      <td class="grid-action">
        <button (click)="openDetails(item)" type="button" class="btn btn-grid btn-round mdi mdi-info mdi-lead-pencil"></button>
        <button *ngIf="!isInArchiveMode" [disabled]="item.IsSystemLabel" (click)="deleteItem(item)" type="button" class="btn btn-grid btn-round mdi mdi-danger mdi-trash-can-outline"></button>
      </td>
    </ng-container>

  </t4-list-view>
</div>