import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { localDateLongPipe, localTimePipe } from "@tymes4-shared";
import { OrderLineRevision } from "../../api";

@Component({
    selector: 'app-order-revision-details',
    templateUrl: './order-revision-details.component.html',
    styleUrls: ['./order-revision-details.component.scss']
  })
  export class OrderRevisionDetailsComponent implements OnInit {
    public original: any; // OrderLineRevision
    public revision: any; // OrderLineRevision
    public isIbaActive: boolean = false;
    public showOriginalPriceHover: boolean = false;
    public showRevisionPriceHover: boolean = false;
    public date: string;
    public localTime: string;
    public numberOfChanges: number = 0;
    public selectedIndex: number;
    constructor(
        @Inject(MAT_DIALOG_DATA) public passedData: { revisions: OrderLineRevision[], selected: number },
        public dialogRef: MatDialogRef<OrderRevisionDetailsComponent>,
        public translate: TranslateService,
        private localDatePipe: localDateLongPipe,
        private localTimePipe: localTimePipe,
    ) {}

    ngOnInit() {
      this.selectedIndex = this.passedData.revisions.findIndex(x => x.Id == this.passedData.selected);
      this.setRevision();
      this.date = this.localDatePipe.transform(this.revision.Created, true);
      this.localTime = this.localTimePipe.transform(this.revision.Created, true);
      var acceptedKeys = ['AppliedCredit', 'AppliedDiscountId', 'CustomerCalculatedName', 'CustomerPassePartoutId', 'DeliveryMethodId',
        'EntranceId', 'EventPlacementId', 'ManualPrice', 'NewBarcode', 'PriceVariationId'];

      this.numberOfChanges = Object.keys(this.revision).filter((key)=> this.revision[key] != null &&acceptedKeys.includes(key)).length;
    }

    constructCustomerIdentificationModel(revision) {
      return {
        CalculatedName : revision.CustomerCalculatedName,
        IsIdentified: revision.CustomerIsIdentified,
        CustomerNumber: revision.CustomerNumber,
      }
    }

    toggleOriginalPriceHover() {
      this.showOriginalPriceHover = !this.showOriginalPriceHover;
    }

    toggleRevisionPriceHover() {
      this.showRevisionPriceHover = !this.showRevisionPriceHover;
    }

    calculateLineBalance() {
      return this.original.NewPrice - this.revision.NewPrice;
    }

    setRevision() {
      this.revision = this.passedData.revisions[this.selectedIndex];
      this.original = this.passedData.revisions[this.selectedIndex+1];
    }

    changeRevision(type: string) {
      if (type == "next")
        this.selectedIndex--;
      else if (type == "prev")
        this.selectedIndex++;

      this.setRevision();
    }
}
