/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SubscriptionView } from './subscriptionView';
import { OrderDiscountTotals } from './orderDiscountTotals';
import { IdentificationCustomer } from './identificationCustomer';
import { OrderLineViewWithPricing } from './orderLineViewWithPricing';
import { OrderTypeEnum } from './orderTypeEnum';


export interface OrderDetailsView { 
    AmountPayed?: number;
    Balance?: number;
    PendingPaymentBalance?: number;
    Payed?: boolean;
    Id?: number;
    PrimaryContactEmail?: string | null;
    UsedPaymentMethods?: string | null;
    OrderLines?: Array<OrderLineViewWithPricing> | null;
    OrderType?: OrderTypeEnum;
    OrderDate?: Date | null;
    Reference?: string | null;
    TotalInclVat?: number;
    SubscriptionViews?: Array<SubscriptionView> | null;
    HasProlongationLines?: boolean | null;
    PayPerMailAllowed?: boolean;
    Locked?: boolean;
    DeliveryMethodId?: number | null;
    DeliveryMethodCode?: string | null;
    DeliveryMethodName?: string | null;
    PayByInvoice?: boolean;
    SalesChannelName?: string | null;
    SalesChannelId?: number;
    CustomerId?: number;
    CustomerName?: string | null;
    CustomerEmail?: string | null;
    OrderIdentificationCustomer?: IdentificationCustomer;
    OrderAddressLine1?: string | null;
    OrderAddressPostalCode?: string | null;
    OrderAddressCity?: string | null;
    PendingCancelByCustomer?: boolean;
    AllowHardcardPrinting?: boolean;
    AllowEticketPrinting?: boolean;
    AllowSoftcard?: boolean;
    CanProlongOrder?: boolean;
    ContactId?: number | null;
    AllowEticketMailing?: boolean;
    IsInvoiced?: boolean;
    HasSecondaryTicketStatus?: boolean;
    PaymentMethodId?: number | null;
    TotalCredit?: number | null;
    CreatorFirstName?: string | null;
    CreatorMiddleName?: string | null;
    CreatorLastName?: string | null;
    DiscountTotals?: Array<OrderDiscountTotals> | null;
    readonly ContainstRecurrentMemberships?: boolean;
    readonly ProductsSubTotalAmount?: number;
    readonly PaymentMethodSubTotalAmount?: number;
    readonly DeliveryMethodSubTotalAmount?: number;
    readonly DiscountSubTotalAmount?: number;
}

