/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DirectDebitPaymentMethod } from './directDebitPaymentMethod';
import { OrderLineView } from './orderLineView';


export interface OrderPaymentInfo { 
    OrderId?: number;
    OrderBalance?: number;
    OrderPendingPaymentBalance?: number;
    Remaining?: number;
    TotalExVat?: number;
    TotalInclVat?: number;
    VAT?: number;
    CustomerId?: number;
    CustomerNumber?: string | null;
    CustomerTotalCredit?: number | null;
    OrderDate?: Date;
    DeliveryMethodName?: string | null;
    CollectPaymentAllowed?: boolean;
    CustomerHasEmailSpecified?: boolean;
    DirectDebitPaymentMethods?: Array<DirectDebitPaymentMethod> | null;
    OrderLines?: Array<OrderLineView> | null;
}

