import {registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';

import localeNl from '@angular/common/locales/nl';
import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {NgSelectModule} from '@ng-select/ng-select';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';

import {
  ConfirmModule,
  ConfirmService,
  EnvironmentModule,
  EnvironmentService,
  HttpLoaderService,
  LayoutsModule, 
  LoaderModule,
  PipesModule,
  RoutePartsService,
  SnackbarModule,
  SnackbarService,
  TranslateHttpLoader,
  VersionCheckService,
  InjectComponentModule,
  FormExtensionModule,
  UploadMessageModule,
  UploadMessageService,
  NotificationModule,
} from '@tymes4-shared';


import {QuillModule} from 'ngx-quill';
import {AppComponent} from './app.component';

import {rootRouterConfig} from './app.routing';
import {HttpErrorHandler} from './shared/common/errors/http-error-handler';
import {NgxEditorModule} from './shared/components/common/ngx-editor/ngx-editor.module';
import {NavHeaderComponent} from './shared/components/nav-header/nav-header.component';
import {AuthInterceptor} from './shared/services/auth/auth.interceptor';
import {SharedModule} from './shared/shared.module';
import {StaticSharedModule} from './shared/static-shared.module';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2'; //Tymes4v2
import {CanDeactivateGuard} from "./shared/components/can-deactivate/can-deactivate.guard";
import { AppCurrencyPipe } from './shared/pipes/currency.pipe';
import { ApiModule, EntraService } from './shared/api';
import { apiConfigFactory } from './shared/config/api.config.factory';
import { BASE_BO_CONFIG } from './injectors';
import { MaintenanceInterceptor } from './shared/services/auth/maintenance.interceptor';
import { ResultHandler } from './shared/common/result-handler';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalService } from '@azure/msal-angular';
import {  InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { MsalConfigService } from './shared/services/msal-config-service';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function loadMsalConfig(msalConfigService: MsalConfigService): () => Promise<PublicClientApplication> {
  return () => new Promise((resolve, reject) => {
    const config = msalConfigService.getMsalConfig();
    if (config) {
      resolve(new PublicClientApplication(config));
    } else {
      reject('MSAL configuration could not be loaded.');
    }
  });
}

export function msalInstanceFactory(msalConfigService: MsalConfigService): PublicClientApplication {
  const config = msalConfigService.getMsalConfig();
  if (!config) {
    throw new Error('MSAL configuration not found in sessionStorage.');
  }
  var instance = new PublicClientApplication(config);
  instance.initialize();
  return instance;
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set("https://graph.microsoft.com/v1.0/me", ["user.read"]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    },
    loginFailedRoute: "./login-failed"
  };
}


registerLocaleData(localeNl, 'nl-NL');

@NgModule({
  imports: [
    [ApiModule.forRoot(apiConfigFactory)],
    NotificationModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    StaticSharedModule.forRoot(),
    NgxEditorModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgSelectModule,
    ConfirmModule,
    MsalModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateHttpLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient, EnvironmentService]
      }
    }),

    RouterModule.forRoot(rootRouterConfig, {useHash: false}),
    QuillModule.forRoot(),
    EnvironmentModule,
    SnackbarModule,
    LayoutsModule,
    PipesModule,
    LoaderModule,
    InjectComponentModule,
    FormExtensionModule,
    UploadMessageModule,
    MonacoEditorModule.forRoot() // use forRoot() in main app module only.
  ],
  declarations: [AppComponent,
    AppCurrencyPipe,
    NavHeaderComponent,
    NavHeaderComponent,
  ],
  providers: [

    //TOKEN INTERCEPTOR
    {
      provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: MaintenanceInterceptor, multi: true
    },
    //Global Webservice errorhandler
    {
      provide: ErrorHandler, useClass: HttpErrorHandler
    },

    // //Entra
    MsalService,
    MsalGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: loadMsalConfig,
      deps: [MsalConfigService],
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: msalInstanceFactory,
      deps: [MsalConfigService]
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
      deps: [MsalConfigService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    //END Entra

    {provide: MAT_DATE_LOCALE, useValue: 'nl-NL'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {provide: LOCALE_ID, useValue: 'nl-NL'},
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true}},
    AppCurrencyPipe,
    ConfirmService,
    VersionCheckService,
    RoutePartsService,
    TranslateService,
    SnackbarService,
    HttpLoaderService,
    ResultHandler,
    CanDeactivateGuard,
    UploadMessageService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

